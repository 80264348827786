
.k-container{
    position: relative;
    /* margin-bottom: 10px; */
}

/* .k-toggle{
    position: absolute;
} */

/* .k-container input{

} */

.hg-theme-default{
    width: auto;
    z-index: 10;
    position: fixed;
    overflow: inherit;
    background-color: #bcbcbc;
}

.k-close{
    position: absolute;
    right: -11px;
    top: -17px;
}

.k-close button{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    background: #ff2b2b;
    cursor: pointer;
    font-size: 14px;
}


.k-close button:hover{
    background: #f12e2e;
}


.k-input-in-form{
    border: 1px solid #000 !important;
    height: 36px !important;
    border-radius: 15px !important;
}

.k-close{
    display: none;
}


/* numbers width */
.k-container [data-skbtn="1"],
.k-container [data-skbtn="2"],
.k-container [data-skbtn="3"],
.k-container [data-skbtn="4"],
.k-container [data-skbtn="5"],
.k-container [data-skbtn="6"],
.k-container [data-skbtn="7"],
.k-container [data-skbtn="8"],
.k-container [data-skbtn="9"],
.k-container [data-skbtn="0"]
{
    width: 10px !important;
}

.k-clear-input-x{
    position: absolute;
    top: 4px;
    left: 7px;
    margin: 5px;
    color: #adadad;
    font-size: 16px;
    transition: .1s;
    opacity: 0;
    cursor: pointer;
}

.k-clear-input-x:hover{
    color: #8b8b8b;
}

.k-container:hover .k-clear-input-x{
    opacity: 1;
}

.clear-btn-keyboard{
    background-color: #ffefff !important;

}