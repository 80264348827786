.suggestions-row-wrapper{
    background: #bf5ade36;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -16px;
    border-radius: 5px;
    margin-bottom: 2px;
    width: 95%;
    position: relative;
    z-index: 2;
}

.suggestion-item{
    display: flex;
    align-items: center;
}

.suggestion-text{
    color: #0000009d;
    font-weight: bold;
    cursor: pointer;
    padding: 2px 10px;
    transition: .15s;
    border-radius: 4px;
    width: 100%;
    text-align: center;
}

.suggestion-text:hover{
    color: black;
    background: #bf5ade4f;
}

.suggestion-separator{
    width: 2px;
    background-color: blueviolet;
    height: 16px;
    margin: 0 10px;
}
