@media (min-width:1200px) {
  .container {
    max-width: 1300px;
  }

}

body,
html {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
}

/* h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 44px;
  font-size: 36px;
} */

/* h2,
.deal-banner .caption h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 38px;
  font-size: 30px;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 34px;
  font-size: 26px;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 30px;
  font-size: 22px;
}

h5,
.testimonials h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 28px;
  font-size: 20px;
}

h6,
.share h5,
.share ul,
.sidebar .widget .item-detail h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 22px;
  font-size: 18px;
} */

/*--------------------  Revolution Layer Style Start  -------------------*/
#rev_slider_24_1 .metis.tparrows {
  background: white;
  padding: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
}

#rev_slider_24_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.75);
}

#rev_slider_24_1 .metis.tparrows:before {
  color: black;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

#rev_slider_24_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}

/*-------------------- Section title Start  -------------------*/
.section-title {
  margin-bottom: 35px;
}

.section-title h2.title {
  text-align: center;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.sub-title {
  margin-top: -10px;
  margin-bottom: 40px;
}

/*--------------------  Top Info Section Start  -------------------*/
.top-info-box {
  background-color: #fef0ea;
}

.top-info-box .section-title {
  padding-top: 15px;
  padding-bottom: 15px;
}

.top-info-box .section-title h6 {
  font-size: 17px;
  text-align: center;
  font-family: Lato;
  font-weight: 700;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 0px;
}

.border-section {
  border-left: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
  border-top: 0px;
  border-bottom: 0px;
}

/*--------------------  Client Slider Start  -------------------*/
.client-slider {
  margin-top: 30px;
}

/*--------------------  Categoru Slider Start  -------------------*/
.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-inner-wrapper .ciyashop_banner-text-wrap-1 .ciyashop_banner-text.tag-text {
  font-size: 0.23em;
  color: #323232;
  background-color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 6px;
  line-height: 30px;
  font-weight: 600;
  padding: 7px 15px;
  margin-bottom: 5px;
}

.ciyashop_banner .ciyashop_banner-content-inner-wrapper {
  font-size: 70px;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-inner-wrapper .ciyashop_banner-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.42em;
  color: #323232;
  line-height: 55px;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-inner-wrapper .ciyashop_banner-btn-wrap.ciyashop_banner-btn-style-link .ciyashop_banner-btn {
  color: #323232;
}

.ciyashop_banner.ciyashop_banner-style-deal-1 .ciyashop_banner-content .ciyashop_banner-content-wrapper .ciyashop_banner-content-inner-wrapper .ciyashop_banner-text-wrap .ciyashop_banner-text h2 {
  font-size: 62px;
  font-weight: 600;
}

.ciyashop_banner.ciyashop_banner-style-deal-1 .ciyashop_banner-content-inner-wrapper .ciyashop_banner-btn-wrap.ciyashop_banner-btn-style-flat .ciyashop_banner-btn {
  background: #ffffff;
}

/*--------------------  Newsletter Start  -------------------*/
.newsletter-bg {
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #e3f6fc;
  padding-bottom: 25px;
}

.newsletter-bg h4.title {
  text-align: center;
  font-weight: 600;
}

/*--------------------  testimonial  Start  -------------------*/
.testimonial-bg {
  margin-right: 0px;
  margin-left: 0px;
  background-image: url(../../assets//images/categories/modern/testimonial-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*--------------------  Latest Post Start  -------------------*/
.latest-post-wrapper .latest-post-item .avatar-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50px;
}
