#inputs-list-wrapper{
    position: relative;
    height: inherit !important;
    overflow: auto;
    max-height: 420px !important;
}

.inputs-list-container{
    /* same as .pd-end-buttons max-width in productDetails.css */
    max-width: 380px;
    overflow-y: auto;
    overflow-x: hidden;
}

.inputs-list-container input[type="text"] {
    outline: none;
    border: none;
    background: #fff;
    margin-bottom: 15px;
    text-align: center;
    width: 360px;
    font-weight: bold;
    color: #000;
    font-size: 18px;
    padding: 19px 29px !important;
}

.inputs-list-container div[tabindex="0"]:focus-visible{
    outline: none !important;
}

.inputs-list-input-w{
    display: flex;
    width: 100%;
}

.inputs-list-input-w input{
    width: 100%;
    /* margin-bottom: 0 !important; */
}

.inputs-list-input-w .k-container,
.inputs-list-input-w .autocomplete-as-smartphone-wrapper,
.inputs-list-input-w .hebrew-date-wrapper,
.inputs-list-input-w .autocomplete-input-wrapper{
    width: 100%;
}


@media screen and (max-width: 1000){
    .inputs-list-container input[type="text"] {
        width: 350px;
    }
}