.preview-modal-content {
    border-radius: 36px;
    padding: 18px;
}
.preview-container-btn{
    color: #BF5ADE;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transition: .2s;
    width: 77px;
    display: flex;
    justify-content: space-between;
}


.product-detail-wrapper .preview-container-btn i.fa-eye{
    margin-right: 5px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    top: -1px;
}

.preview-container-btn.disabled,
.preview-container-btn.disabled:hover {
    color: #BF5ADE;
    cursor: not-allowed;
    opacity: 0.3;
}

.preview-container-btn:hover{
    color: #e9a6ff;
}

/* for edit purchase page */
.edit-purchase-class .preview-container-btn{
    color: #BF5ADE;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transition: .2s;
    width: 77px !important;
    display: flex !important;
    justify-content: space-between !important;
    transform: translateY(-50%);
}

.preview-container-btn i{
    margin-right: 5px;
    margin-top: 5px;
}

/* for edit purchase page */
.edit-purchase-class .preview-container-btn i{
    margin-right: 5px;
    margin-top: 5px !important;
}
.canvas-mockup-image {
    border-radius: 16px;
    background-color: rgb(167, 167, 167);
}
.canvas-mockup-image.active {
    box-shadow: 0 0 0 2px #000 !important;
}
.canvas-mockup-image.pointer {
    cursor: pointer;
}
.close-icon {
    position: absolute;
    right: -72px;
    cursor: pointer;
    top: -18px;
    width: 40px;
    height: 40px;
}

.columns-preview-wrapper{
    display: flex;
    justify-content: space-between;

}

.col-preview-1{
    display: flex;
}

.preview-thumbnails-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.preview-thumbnail-box{
    margin-bottom: 10px;
    display: flex;
}
.preview-thumbnail-box:last-child{
    margin-bottom: 0;
}


@media screen and (max-width: 1000px){
    .columns-preview-wrapper{
        flex-direction: column;
    }
    .preview-thumbnails-wrapper{
        flex-direction: row;
        margin-bottom: 0;
        margin-top: 20px;
    }
    .preview-thumbnail-box{
        margin-right: 10px;
        margin-bottom: 0;
    }
    .preview-thumbnail-box:last-child{
        margin-right: 0;
    }
}

@media screen and (max-width: 576px){
    .edit-purchase-class .preview-container-btn{
        width: 82px !important;
    }
}

@media screen and (max-width: 500px){
    .preview-thumbnails-wrapper{
        margin-top: 15px;
    }
    .preview-thumbnail-box{
        margin-right: 7px;
    }
    .preview-thumbnail-box:last-child{
        margin-right: 0;
    }
    .preview-container .modal-body{
        padding: 0;
    }
    .custom-modal-preview-img .modal-body{
        padding: 0 !important;
    }
}