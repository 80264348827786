.simchas-drop-down {
    padding: 40px 0px 40px 35px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    width: 220px;
    color: black;
    position: absolute;
    top: 30px;
    left: -19px;
    transition: visibility 0.3s ease;
    /* visibility: hidden; */
    z-index: 10;
}

.simchas-drop-down.display-none {
    display: none;
}

.simchas-drop-down.active {
    animation-name: simchasMenuOpen;
    animation-duration: 0.3s;
}

.simchas-drop-down.hidden {
    animation-name: simchasMenuClose;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.simchas-drop-down .drop-down-link {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 130px;
    margin-bottom: 20px;
    font-size: 13.5px;
    font-weight: var(--font-weight-600);
}
.simchas-drop-down .drop-down-link-icon-wrap:last-of-type .drop-down-link {
    margin-bottom: 0;
}
.simchas-drop-down .drop-down-link:hover {
    color: var(--nav-link-hover-color);
    transition: 0.5s;
    font-weight: var(--font-weight-600);
}

.simchas-drop-down .drop-down-link-icon-wrap {
    position: relative;
    display: flex;
}

.simchas-drop-down .drop-down-link-icon-wrap .arrow-icon {
    margin-bottom: 20px;
}

.simchas-drop-down .drop-down-link-icon-wrap:last-of-type .arrow-icon {
    margin-bottom: 0px;
}

.simchas-drop-down .drop-down-icon {
    color: black;
    font-size: 20px;
}

.drop-down-link-icon-wrap .right-arrow {
    font-size: 25px;
}

@keyframes simchasMenuOpen {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes simchasMenuClose {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}
