.hero-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    /* position: relative; */
    align-items: center;
    /* top: 73px; */
    margin-top: 2%;
}

.hero {
    max-width: var(--container-max-width);
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
}

.hero-left {
    width: 43%;
    z-index: 2;
    margin-top: -64px;
}

.hero-left .hero-hd {
    color: #000000;
    font-family: var(--Nexa-font);
    font-size: var(--hd-font-size);
    font-weight: var(--font-weight-600);
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 72px;
    letter-spacing: 0px;
    margin-top: 0px;
}

.hero-left .hero-text {
    font-size: 24px;
    color: #000000da;
    font-family: var(--Nexa-font);
    line-height: 34px;
    letter-spacing: -0.648px;
    margin: 54px 0px 40px 0px;
    font-weight: 500;
    max-width: 526px;
}

.hero-right {
    width: 57%;
    /* position: relative; */
    /* overflow: hidden; */
    /* margin-top: -60px; */
}
.hero-right > div:first-child {
    max-width: 700px;
    text-align: left;
    margin: -64px 0 0 80px !important;
}

.hero-video {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 1)));
    pointer-events: none;
    margin: -64px 0 0 80px !important;
    transform: scale(1.3);
    max-width: 700px;
}

.hero-btns {
    display: flex;
    gap: 20px;
}

.hero-left .section-subtitle {
    margin: 34px 0 42px;
    max-width: 526px;
}

.btn {
    height: 62px;
    min-width: 220px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: capitalize;
    letter-spacing: -0.5px;
    color: #000000;
    border: 2px solid transparent !important;
    border-radius: 15px !important;
    padding: 10px 20px;
    transition: all 0.3s ease;
    z-index: 1;
}

.hero-container .btn--contained {
    color: #fff !important;
    background-color: var(--main-purple) !important;
    border-color: var(--main-purple) !important;
}

.btn--contained:hover {
    color: var(--main-purple) !important;
    background-color: #fff !important;
}

.btn.btn--outlined {
    border-color: black !important;
    color: black !important;
}

.hero-container .btn--outlined:hover {
    background-color: #fff;
    color: var(--main-purple) !important;
    border-color: var(--main-purple) !important;
}

.hero-btns.hidden {
    display: none;
}

@media (max-width: 1080px) {
    .hero-left {
        margin: 0px;
    }
}

@media (max-width: 1024px) {
    .hero-container {
        height: auto;
        position: unset;
    }

    .hero {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero-left {
        margin-top: 70px;
        width: 80%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .hero-left .hero-hd {
        font-size: 34px;
        text-align: center;
        line-height: 50px;
        color: #000000;
    }

    .hero-left .hero-text {
        margin-top: 0px;
        font-weight: 400;
        text-align: center;
        font-size: 20px;
        letter-spacing: -0.8px;
    }

    .hero-right {
        /* position: unset; */
        width: 80%;
        /* margin-left: unset; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .hero-video,
    .hero-right > div:first-child {
        margin: 0 !important;
        margin-left: 4% !important;
        max-width: 500px;
    }
    .hero-video {
        transform: scale(1);
    }

    .hero-btns.visible {
        display: none;
    }

    .hero-btns.hidden {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    /* 
    .hero-left .section-subtitle {
        margin: 0 auto 24px;
        max-width: 60%;
    } */
    .btn.btn--contained,
    .btn.btn--outlined {
        font-size: 14px !important;
    }
}

@media (max-width: 750px) {
    .btn {
        height: 44px;
        min-width: 160px;
        font-size: 11px !important;
        border-radius: 14px !important;
    }

    .hero-left .section-subtitle {
        margin: 0 auto 24px;
        max-width: 100%;
    }

    .hero-left {
        margin-top: 40px;
        width: 80%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .hero-right {
        width: 100%;
        margin-top: 0px;
    }
}
@media (max-width: 767px) {
    .hero {
        padding: 0px 16px;
    }
    .btn.btn--contained,
    .btn.btn--outlined {
        font-size: 12px !important;
    }
    .hero-left .hero-hd {
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: black;
    }
    .hero-left .hero-text {
        margin-top: 0px;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        max-width: 60%;
        letter-spacing: normal;
    }
}

@media (max-width: 575px) {
    .hero-left .hero-hd {
       max-width: 260px;
    }
}

@media (max-width: 575px) {
    .hero-left .hero-hd {
        font-size: 26px;
    }
}

@media (max-width: 424px) {
    .hero-left {
        width: 85%;
    }
}

@media (max-width: 374px) {
    .hero-left {
        width: 90%;
    }
}

@media (max-width: 374px) {
    .hero-btns a {
        width: 100%;
    }
}
