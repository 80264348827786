body {
    font-family: var(--Nexa-font) !important;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

.ml-2 {
    margin-left: 20px;
}

.whishlist-btn-item,
.product-info-wrapper .align-items-center {
    cursor: pointer;
}

.sidebar-category-filter {
    position: fixed !important;
}

.products-content-wrapper {
    margin-left: 360px !important;
    /* height: 100vh;
    overflow-y: scroll; */
    padding-bottom: 80px;
}

.shop-sidebar-widgets::-webkit-scrollbar {
    display: none;
}

.wishlist-remove-btn i:hover {
    color: var(--main-purple);
}

.pd-nameprice h3,
.pd-nameprice h4 {
    font-family: var(--Nexa-font) !important;
}

.wishlist-item-down .product-nameprice {
    padding: 0px 11px 0px;
}

.inputs-list-container input[type="text"] {
    width: 95%;
    font-weight: 300;
    padding: 20px 30px !important;
    font-size: 14px;
    margin-bottom: 20px;
}

#cartbtn-buynow {
    background-color: var(--main-purple);
    color: white;
}

#cartbtn-buynow,
#cartbtn-cart {
    padding: 14px;
    font-size: 16px;
    font-weight: 400;
    height: 62px;
    border-radius: 15px;
    min-width: 49%;
    display: flex;
    align-items: center;
}

#cartbtn-buynow:hover {
    color: var(--main-purple) !important;
    background-color: #faf7ff;
    border: 2px solid var(--main-purple);
    font-weight: bolder;
}

.edit-purchase-class {
    display: flex;
    justify-content: center;
    padding-top: 60px;
}

.product-detail-class .pd-left-column {
    width: 50% !important;
}

.hebrew-date-wrapper {
    margin-bottom: 0px;
}

.pd-flex-container {
    width: 100%;
    max-width: 1100px;
}

.pd-nameprice {
    margin-top: 0px;
}

#pd-hr {
    height: 1px !important;
    margin-top: 25px !important;
    display: none;
}
.inputs-list-container {
    max-width: 100%;
}

.pd-nameprice h4 {
    font-size: 15px;
    margin-bottom: -5px;
    font-weight: 400;
    color: #000;
}

.pd-nameprice h3 {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}
.product-detail-wrapper .pd-checkboxes {
    margin-bottom: 20px;
}
.product-detail-wrapper .pd-checkbox label {
    font-weight: 500 !important;
    font-size: 20px;
}
.product-detail-wrapper .pd-checkbox input[type="checkbox"] {
    top: 0;
}
.product-detail-wrapper .preview-container-btn {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-detail-wrapper .preview-container-btn i.fa-eye {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    top: 2px;
}

.product-detail-wrapper .preview-container-btn i.fa-eye:before {
    content: "";
    height: 16px;
    top: auto;
    left: 0px;
}

#btn-qr {
    background: var(--main-purple);
    border: 2px solid var(--main-purple);
    font-size: 16px;
    padding: 5px;
    border-radius: 10px;
    transition: .15s;
    height: 44px;
}

#btn-qr:hover {
    background: #fff;
    color: var(--main-purple);
    border: 2px solid var(--main-purple);
}

.pd-checkbox label {
    font-weight: 400;
}

.cartbtn-container button {
    font-size: 14px;
    width: 150px;
    padding: 15px 10px 10px 10px;
}

.cartbtn-container {
    max-width: 100% !important;
}

.product-info-wrapper {
    align-items: flex-end;
}

.product-nameprice {
    padding: 0px 11px 0px;
    line-height: 2;
}

.product-info-wrapper .fa-pencil {
    margin-top: 7px;
}

.product-thumbnail {
    min-height: 284.625px !important;
    background: #f5f5f5;
    border-radius: 17px;
    overflow: hidden;
    flex: 1;
    /* height: 75%; */
}

.product-thumbnail-inner div {
    height: 100%;
}
/* 
.product-thumbnail-inner div{
height: 100%;
}

.product-thumbnail-inner div img{
height: 100%;
} */

.product-thumbnail-inner > a > div {
    transition: 0.3s all;
}

.product-thumbnail-inner > a > div:hover {
    transform: scale(1.2);
}

.sidebar-category-filter::-webkit-scrollbar {
    display: none;
}

.sidebar-category-filter {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

input[type="checkbox"] {
    min-width: 1.286em;
    min-height: 1.286em;
    background-color: #fafafa;
    border-radius: 30%;
    border: 1px solid #484848;
    appearance: none;
    position: relative;
    top: 3.5px;
}

#inputs-list-wrapper {
    height: calc(100% - 340px);
    overflow: auto;
    max-height: 100%;
}

#inputs-list-wrapper::-webkit-scrollbar {
    width: 13px;
    background: transparent;
}

#inputs-list-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

#inputs-list-wrapper::-webkit-scrollbar-thumb {
    background: #8e8e8e;
    border: 5px solid #e7e7e7;
    border-radius: 10px;
}

#inputs-list-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.product-price {
    padding: 0px 0px 0px 0px;
    font-size: 12px;
    color: #000;
    font-weight: 600;
}

.products-grid-container {
    /* display: flex !important; */
    flex-wrap: wrap;
    gap: 20px !important;
    overflow-y: hidden;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(4, 220px);
    width: fit-content;
    height: fit-content;
    justify-content: flex-start !important;
}

.product-name {
    color: #000;
    font-size: 11px !important;
    font-weight: 100;
    padding: 10px 0px 0px 0px;
    margin-bottom: -5px;
}

.sidebar-category-filter .shop-sidebar-widgets {
    margin: 80px 0px 0 0;
    background: #fff;
    padding: 35px;
    border-radius: 20px !important;
}

.sidebar-category-filter {
    max-width: 300px !important;
    padding: 0 !important;
    padding-bottom: 100px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 26px !important;
    border-bottom-right-radius: 26px !important;
    background: transparent;
    overflow: scroll;
    max-height: 88vh;
    width: 100%;
}

.products-content-wrapper {
    padding-top: 80px;
    margin-bottom: 0px;
    margin-left: 40px;
    /* max-width: 1200px; */
    flex: 1;
    position: relative;
    height: fit-content;
    margin-left: 360px !important;
}

.subcategory-check-container {
    align-items: baseline !important;
    padding: 7.5px 38px !important;
}

.subcategory-check-container .form-check-label {
    color: #212121;
}
.principal-category-filter-link > div {
    padding: 15px 0;
    border-color: #B7B7B7 !important;
}
.category-filter-item:last-child .principal-category-filter-link > div {
    border: none !important;
}
.principal-category-filter-link.item-active > div {
    padding: 0;
    border: none !important;
}
.principal-category-filter-link .chevron-down-icon img {
    width: 13px;
}

.fa-arrow-left:before {
    content: " " !important;
    background: url(https://hazmoona.webarysites.com/wp-content/uploads/2023/08/back.svg);
    padding: 0px 10px;
    background-repeat: no-repeat;
    background-size: 14px;
    top: 1px;
    position: relative;
}

.site-content .content-wrapper {
    padding: 0px;
}

.cat-logo-flex {
    color: #000000;
}
.cat-logo-flex div {
    margin: auto !important;
}
.item-active .cat-logo-flex {
    color: #fff;
}

.fa-pencil:before {
    content: " " !important;
    background: url(https://hazmoona.webarysites.com/wp-content/uploads/2022/04/Edit.svg);
    padding: 0px 7px;
    background-repeat: no-repeat;
    background-size: auto;
}

.fa-pencil {
    font-size: 16px;
    margin-top: 10px;
}

.fa-pencil:hover {
    filter: invert(80%);
}

.sidebar-category-filter .btn-group {
    padding-bottom: 20px;
}

.cat-logo-flex img {
    padding: 1px;
}

.product .product-inner {
    padding: 8px 8px 7px;
    border-radius: 24px;
    /* height: 352px; */
    height: 100%;
    width: 100% !important;
    background: #ffffff;
    display: flex;
    flex-direction: column;
}

.product-detail-wrapper {
    padding: 50px 20px;
}

.product-thumbnail-inner > a > div > img {
    border-radius: 0px;
}

.pd-go-back i {
    color: var(--main-purple);
    font-size: 14px;
}

.pd-go-back span {
    font-size: 18px;
    font-weight: 400;
}

.whishlist-btn-item {
    color: #000;
}

.whishlist-btn-item.is-active svg.unlike-icon {
      fill:#191919;
      animation: beat cubic-bezier(0.04, 0.4, 0.5, 0.95) 450ms forwards 1;
}

@keyframes beat {
  
    30% {
      opacity:1;
      transform: scale(1.4);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

.principal-category-filter-link.item-active {
    padding: 15px 18px;
    align-items: start;
    margin-bottom: 5px;
}

.cat-logo-flex div {
    letter-spacing: 0.5px;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
}

#Icon_ionic-ios-heart {
    stroke-width: 0.5;
}

button.btn-editProduct {
    color: black;
    font-size: 0px;
    background: transparent;
    box-shadow: none;
}

.products-grid-container .product-thumbnail-inner > a > div > img {
    position: relative;
    /* width: 225px !important; */
}

.cat-logo-flex img {
    margin-right: 10px;
    max-width: 23px;
    max-height: 23px;
}

.downloads-class img {
    height: 30px;
    width: auto;
    filter: brightness(0) invert(1);
}

.fa-eye:before {
    content: "";
    width: 19px;
    height: 19px;
    background: url(https://hazmoona.webarysites.com/wp-content/uploads/2022/07/Preview.svg) no-repeat 0px 1px;
    position: absolute;
    display: inline-block;
    background-size: 16px;
}

.product-detail-wrapper .pd-go-back {
    padding-bottom: 10px;
}

.product-detail-wrapper .pd-right-column {
    padding: 50px 0px 0 !important;
    margin-left: 40px !important;
}

.content-full {
    background-image: linear-gradient(#fcf7ff, #fff);
}

input[type="checkbox"]:hover {
    background: #b5b5b5;
}

input[type="checkbox"]:checked {
    background: #c7c7c7;
    position: relative;
    border: 0;
}

.all-flex-to-full-height {
    /* padding: 0px 60px; */
    overflow-x: hidden;
}

input[type="checkbox"]:checked ~ input[type="checkbox"]:after {
    border: solid #b5b5b5;
}

.header-container .header-sub-container {
    position: relative !important;
}

.section-ptb .single-order-box .order-content {
    display: flex !important;
    flex-direction: row !important;
    /* align-items: end !important; */
    box-shadow: none !important;
}

input[type="checkbox"]:after {
    left: 50%;
    top: 44%;
    width: 6px;
    height: 10px;
    border: solid #000000;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    position: absolute;
    opacity: 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

input[type="checkbox"]:checked::after {
    opacity: 1;
}

.wishlist-remove-btn i {
    color: #d81e5e !important;
}

.preview-modal-content {
    border-radius: 23px !important;
    padding: 18px !important;
}

.canvas-mockup-image {
    background-color: #a7a7a7;
    border-radius: 9px !important;
}

.canvas-mockup-image.active {
    box-shadow: 0 0 0 1px #000;
}

.close-icon {
    height: 20px;
    position: absolute;
    right: -50px;
    top: -25px;
    width: 40px;
}

#sidebar-filter-toggler-wrapper {
    border-radius: 10px;
    display: none;
}

.pd-checkboxes {
    margin-top: 35px !important;
    padding-top: 35px !important;
    border-top: 1px solid #bababa;
}

.elementor-menu-cart__product-price.product-price bdi {
    margin-left: -12px;
}

.product-detail-wrapper .pd-left-column .canvas-wrapper {
    width: 100% !important;
    height: 60vh !important;
    touch-action: auto;
    user-select: none;
    object-fit: contain;
}
.product-detail-wrapper .pd-left-column .upper-canvas {
    position: absolute !important;
    z-index: -1 !important;
}
.product-detail-wrapper .pd-left-column canvas {
    position: unset !important;
    width: 100% !important;
    touch-action: auto !important;
}

.dp__btn-item-description {
    font-family: var(--Nexa-font);
    font-weight: 300;
    font-size: 20px;
    color: #707070;
}

.dp__format-text {
    color: #fff;
    font-family: var(--Nexa-font);
    font-size: 21px;
    font-weight: 300;
    margin-top: 6px;
}

.dp__format-button {
    align-items: center;
    background-color: #ba26ff;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 10px;
    padding: 14px 32px;
    position: relative;
    transition: 0.1s;
}

.downloads-class img {
    height: 21px;
    width: auto;
    filter: brightness(0) invert(1);
}

.canvas-container {
    width: 100vw;
    height: 60vh !important;
    position: relative;
    user-select: none;
    margin: 0 auto;
}

.lower-canvas,
.canvas-container {
    max-width: 100% !important;
    height: auto !important;
}

.custom-scroll::-webkit-scrollbar {
    width: 13px;
    background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #8e8e8e;
    border: 5px solid #e7e7e7;
    border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

@media (max-width: 1400px) {
    .products-content-wrapper {
        margin-left: 330px !important;
    }

    .products-grid-container {
        gap: 20px !important;
    }

    /* .product .product-inner {
        height: 332px;
        width: 215px;
    } */

    .product-thumbnail {
        min-height: 180px !important;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1398px;
    }
}

@media screen and (max-width: 1024px) {
    .sidebar-category-filter {
        max-width: 280px !important;
    }
    .products-content-wrapper {
        margin-left: 300px !important;
    }
}

@media screen and (max-width: 992px) {
    .close-icon {
        height: 30px;
        position: absolute;
        right: -50px;
        top: -25px;
        width: 30px;
    }

    .canvas-mockup-image {
        background-color: #a7a7a7;
        border-radius: 16px;
        max-height: 100%;
        max-width: 100%;
    }

    .product-detail-wrapper .pd-right-column {
        /* margin-left: 0px !important; */
    }

    .pd-flex-container {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 768px) {
    .sidebar-category-filter .shop-sidebar-widgets {
        margin: 0px 0px;
    }

    .site-content .inner-intro {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .product-detail-class .pd-left-column {
        width: 54% !important;
    }

    body .product-detail-wrapper .pd-right-column {
        width: 100%;
        margin-left: 0px !important;
        min-width: 100%;
        margin-top: 20px !important;
    }

    #inputs-list-wrapper {
        height: inherit !important;
        max-height: 420px !important;
        overflow: auto;
        position: relative;
        top: 20px !important;
        left: 5px;
    }

    body .product-detail-wrapper {
        width: 100%;
        margin: 0 auto;
    }

    body .sidebar-category-filter {
        background: #fff;
        border-radius: 12px !important;
        max-width: 100% !important;
        min-height: auto;
        opacity: 1;
        overflow-y: auto;
        padding: 35px;
        position: -webkit-relative;
        position: relative !important;
        top: 0;
    }

    .widget.widget_layered_nav.widget-layered-nav.pgs_widget-layered-nav {
        width: 100%;
        /* display: flex;
        flex-wrap: wrap; */
    }

    .products-content-wrapper {
        margin-top: 0px;
        margin-bottom: 80px;
        padding-top: 30px;
        flex: 1 1;
        margin-left: 0px !important;
        position: relative;
    }

    .products-grid-container {
        /* display: flex !important; */
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center !important;
    }

    .products-content-wrapper {
        margin-left: 0px !important;
    }

    #sidebar-filter-toggler-wrapper {
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 8px 16px;
        margin: 20px 0 0;
        border: 1px solid transparent;
        border-radius: 12px;
    }

    .product-grid-item {
        /* margin: 5px auto; */
        width: 50%;
        /* max-width: 218px; */
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

    #sidebar-toggler-button {
        border: none;
        background: transparent;
    }

    #sidebar-toggler-button img {
        width: 20px;
    }

    #filter-toggler-txt {
        font-weight: 400;
        font-size: 14px;
        color: #000;
        margin-top: 2px;
    }

    .sidebar-category-filter {
        height: 0px;
    }

    .sidebar-category-filter.sidebar-visible {
        height: inherit;
    }

    .not-found-items-text {
        bottom: initial !important;
        color: #333;
        display: block !important;
        font-family: var(--Nexa-font);
        left: 0 !important;
        position: relative !important;
        right: 0 !important;
        top: 20px;
        padding: 12px;
    }

    .sidebar-category-filter {
        padding-bottom: 0px !important;
    }

    #cartbtn-buynow,
    #cartbtn-cart {
        padding: 12px;
        font-size: 12px;
        font-weight: 500;
        height: 44px;
        border-radius: 12px;
        max-width: 49%;
        min-width: 48%;
    }
}

@media screen and (max-width: 567px) {
    .product-thumbnail {
        min-height: 150px !important;
    }

    /* .product .product-inner {
        height: auto;
        width: auto;
    } */


    .product-detail-class .pd-left-column {
        width: 100% !important;
        height: auto;
    }
    
    .pd-go-back span {
        display: none;
    }
    .product-name {
        color: #000;
        font-size: 10px !important;
        font-weight: 100;
        padding: 0;
        margin: 11px 0 -8px;
        font-weight: 400;
    }
    .product-price {
        padding: 0px 0px 0px 0px;
        font-size: 10px;
        color: #000;
        font-weight: 600;
    }

    .product-detail-wrapper {
        padding: 20px;
    }

    .pd-nameprice h3,
    .pd-nameprice h4 {
        font-size: 10px;
        font-weight: 500;
        font-style: normal;
        font-weight: normal;
        text-align: right;
        padding: 0;
        margin: 0;
        line-height: 12px;
    }

    .pd-nameprice h4 {
        font-weight: 300;
    }

    .pd-nameprice {
        margin-top: 20px;
        position: absolute;
        right: 20px;
        top: 0;
    }

    .pd-nameprice b {
        font-weight: 500;
    }

    .pd-checkboxes,
    .pd-checkboxes label {
        font-size: 16px !important;
        font-weight: 500 !important;
    }

    .product-detail-wrapper .preview-container-btn {
        font-size: 14px;
        font-weight: 400;
    }

    #cartbtn-buynow,
    #cartbtn-cart {
        padding: 12px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 12px;
        max-width: 49%;
        min-width: 48%;
    }
    .woocommerce .col2-set {
        width: 100%;
        margin-top: 0px !important;
        text-align: center !important;
    }

    .cart_item {
        height: auto;
        display: flex;
        width: 100%;
        padding: 16px !important;
    }

    .page-id-10 .shop_table .product-name {
        border-right: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom: 1px solid #c2c2c2 !important;
        border-top: 1px solid #c2c2c2 !important;
        border-left: 1px solid #c2c2c2 !important;
        height: 160px;
        border-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 8px 16px !important;
        font-size: 20px;
    }

    .cartbtn-container button {
        align-items: center;
        border: none;
        border-radius: 10px;
        display: flex;
        font-size: 16px;
        height: 35px;
        justify-content: center;
        padding: 5px 0;
        position: relative;
        transition: 0.3s;
        width: 160px;
        align-items: center;
        padding: initial !important;
    }

    .product-grid-item {
        /* max-width: 140px; */
        /* height: 230px; */
    }

    body .product-detail-wrapper .pd-right-column {
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 360px) {
    .products-grid-container {
        /* display: flex !important; */
        flex-wrap: wrap;
        gap: 0px !important;
        justify-content: center;
        width: 100%;
    }

    .product-thumbnail {
        min-height: 160px !important;
    }
    .product-grid-item {
        margin: 5px auto;
        width: 50%;
        /* max-width: 140px; */
    }
}

@media (min-width: 1400px) {
    .products-content-wrapper {
        margin-left: 360px !important;
    }

    .product-thumbnail {
        min-height: 254.625px !important;
    }
    /* 
    .product .product-inner {
        height: 346px;
        width: 233px;
    } */
}

/* @media only screen and (min-width: 992px) and (max-width: 1030px) {
    .products-content-wrapper {
        margin-left: 270px !important;
    }
} */

@media only screen and (min-width: 768px) and (max-width: 992px) {
    body .sidebar-category-filter {
        margin: 50px 0px;
    }

    .container,
    .container-md,
    .container-sm {
        max-width: 760px;
    }

    .products-content-wrapper {
        flex: 1 1;
        /* margin-left: 330px !important; */
        position: relative;
    }

    .sidebar-category-filter .shop-sidebar-widgets {
        margin: 30px 0px 80px 0px;
    }

    .sidebar-category-filter {
        margin: 50px 0px !important;
    }
}

@media only screen and (min-width: 350px) and (max-width: 576px) {
    /* .sidebar-category-filter {
        margin: 0px 0px !important;
    } */

    .sidebar-category-filter {
        background: #fff;
        border-radius: 0px !important;
        max-width: 100%;
        min-height: auto;
        opacity: 1;
        overflow-y: auto;
        padding: 35px;
        position: -webkit-relative;
        position: relative !important;
        top: 0;
    }

    .widget.widget_layered_nav.widget-layered-nav.pgs_widget-layered-nav {
        width: 100%;
        /* display: flex;
        flex-wrap: wrap; */
    }

    .products-content-wrapper {
        margin-bottom: 80px;
        flex: 1 1;
        margin-left: 0px !important;
        position: relative;
    }

    .products-grid-container {
        /* display: flex !important; */
        flex-wrap: wrap;
        gap: 10px !important;
        justify-content: center !important;
        width: 100%;
    }
}

@media only screen and (min-width: 340px) and (max-width: 767px) {
    .close-icon {
        height: 30px;
        position: absolute;
        right: -50px;
        top: -25px;
        width: 30px;
    }
    .products-content-wrapper {
        margin-bottom: 0;
    }
}
@media (max-width: 576px) {
    .close-icon {
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .products-content-wrapper {
        margin-bottom: 0;
    }
}
@media (max-width: 425px) {
    .close-icon {
        right: 10px;
        top: 10px;
    }
    .products-grid-container {
        gap: 10px !important;
    }
}

@media only screen and (min-width: 360px) and (max-width: 414px) {
    .product-detail-wrapper .pd-right-column {
        padding: 30px 0px 0 !important;
    }
}

.inputs-list-container input[type="text"] {
    font-size: 15px !important;
}

.inputs-list-container {
    font-family: fbjabutinski-Light !important;
}
.inputs-list-container input[type="text"] {
    font-size: 14px !important;
}
