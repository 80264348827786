.simcha-main-container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* overflow: hidden; */
}

.simcha-main-container .simcha-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 147px;
    font-family: var(--Nexa-font);
    max-width: var(--container-max-width);
}

/* .simcha-container::after {
    z-index: -1;
    content: "";
    width: 100%;
    height: 400px;
    background: url("../../../../src/assets/simchaInvitaion/bg-img.svg") no-repeat;
    bottom: -400px;
    left: 0;
    position: absolute;
    background-size: cover;
    background-position: bottom left;
    background-repeat: no-repeat;
} */

.simcha-container .simcha-text-hd-wrap {
    width: 50%;
    padding: 10px;
}

.simcha-container .simcha-text-hd-wrap .simcha-hd {
    font-size: var(--hd-font-size);
    color: black;
    line-height: 72px;
    font-weight: var(--font-weight-600);
    margin-bottom: 20px;
}

.simcha-container .simcha-text-hd-wrap .simcha-text-wrap .simcha-text {
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 21px;
    letter-spacing: -0.648px;
}

.simcha-container .simcha-sub-hd {
    margin-top: 20px;
    color: var(--main-purple);
    font-size: 24px;
    line-height: 34px;
    font-weight: var(--font-weight-600);
    margin-bottom: 20px;
    letter-spacing: -0.648px;
}

.simcha-container .simcha-button {
    background-color: var(--main-purple);
    font-size: 18px;
    font-weight: var(--font-weight-600);
    text-transform: capitalize;
    font-style: normal;
    text-decoration: none;
    line-height: 0.7em;
    letter-spacing: 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #af40f7;
    border-radius: 15px;
    padding: 10px 24px 10px 24px;
    align-items: center;
    height: 62px;
    color: white;
    margin-top: 20px;
    transition: all 0.3s ease;
}

.simcha-container .simcha-button:hover {
    background-color: white;
    border: 2px solid var(--main-purple);
    color: var(--main-purple);
}

.simcha-container .simcha-img-wrap .img-button {
    display: none;
}

.simcha-container .simcha-img-wrap {
    /* z-index: 1; */
    width: 50%;
    /* position: relative; */
    /* margin-right: -90px; */
}

.simcha-container .simcha-img-wrap .simcha-img {
    max-width: 112% !important;
    width: 112% !important;
    height: auto;
    /* position: absolute; */
    /* right: -100px; */
    /* top: 0px; */
}

.simcha-container .simcha-img-wrap .simcha-res-img {
    display: none;
}

@media (max-width: 1024px) {
    .simcha-container {
        flex-direction: column;
        padding-top: 100px;
    }

    .simcha-container .simcha-text-hd-wrap .text-button {
        display: none;
    }

    .simcha-container .simcha-text-hd-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
    }

    .simcha-container .simcha-text-hd-wrap .simcha-hd {
        text-align: center;
        font-size: 34px;
        line-height: 50px;
    }

    .simcha-container .simcha-text-hd-wrap .simcha-text-wrap .simcha-text {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.8px;
    }

    .simcha-container .simcha-sub-hd {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.8px;
    }

    .simcha-container .simcha-img-wrap .img-button {
        display: flex;
    }

    .simcha-container .simcha-img-wrap {
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        align-items: center;
        margin-top: 70px;
        width: 100%;
        margin-right: 0px;
    }

    .simcha-container .simcha-img-wrap .simcha-img {
        width: 100%;
        height: 740px;
    }
    .simcha-main-container .simcha-container {
        padding-top: 0px;
    }
    .simcha-container .simcha-button {
        font-size: 14px;
        font-weight: 500;
    }
}

@media (max-width: 900px) {
    .simcha-container .simcha-img-wrap .simcha-img {
        width: 100%;
        height: unset;
    }
}

@media (max-width: 767px) {
    .simcha-container .simcha-text-hd-wrap {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .simcha-container::before {
        content: "";
        z-index: 0;
        content: "";
        width: 100%;
        height: 100px;
        background: url("../../../assets/images/hhh.svg") no-repeat;
        bottom: -90px;
        left: 0;
        position: absolute;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(180deg);
    }

    .simcha-container .simcha-text-hd-wrap .simcha-hd {
        font-size: 30px;
        line-height: 35px;
    }

    .simcha-container .simcha-text-hd-wrap .simcha-text-wrap .simcha-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: normal;
    }

    .simcha-container .simcha-sub-hd {
        font-size: 12px;
        line-height: 18px;
        margin-top: 0px;
        letter-spacing: normal;
    }

    .simcha-container .simcha-button {
        background-color: var(--main-purple);
        font-size: 12px;
        font-weight: 500;
        text-transform: capitalize;
        font-style: normal;
        text-decoration: none;
        line-height: 0.7em;
        letter-spacing: 0px;
        border-style: solid;
        border-width: 1px;
        border-color: #af40f7;
        border-radius: 12px;
        padding: 16px 14px 16px 14px;
        color: white;
        margin-top: 20px;
        height: 44px;
    }

    .simcha-container .simcha-img-wrap {
        margin-top: 20px;
    }

    .simcha-container .simcha-img-wrap .simcha-res-img {
        display: flex;
    }

    .simcha-container .simcha-img-wrap .simcha-img {
        display: none;
    }
}

@media (max-width: 550px) {
    .simcha-container .simcha-text-hd-wrap {
        padding: 0;
        max-width: 280px;
    }
}

/* modal css */

.modal .modal-body .custom-modal-hd {
    font-size: 40px;
    margin-bottom: 30px;
    color: black;
    line-height: 37px;
}

.modal .modal-body .custom-modal-input-wrap {
    gap: 23px;
}

.modal-dialog.reach-out-modal-container .modal-content {
    padding: 20px 20px 30px 20px;
    width: 100%;
}
.modal-dialog.reach-out-modal-container .custom-modal-textarea {
    height: 320px;
    padding: 25px 0px 25px 20px;
    position: relative;
    width: 100%;
}

.modal-dialog.reach-out-modal-container .custom-modal-btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    padding: 0px 20px;
    width: 100%;
    /* right: 0px; */
}

.modal-dialog.reach-out-modal-container .custom-modal-attchment-btn {
    display: flex;
    gap: 5px;
    padding: 15px 20px;
    border: none;
    border-radius: 15px;
    font-size: 17px;
    background-color: #f7f7f7;
    font-weight: 500;
    justify-content: center;
}

.modal-dialog.reach-out-modal-container .inputDate {
    display: flex;
    gap: 5px;
    padding: 15px 20px;
    border: none;
    border-radius: 15px;
    font-size: 18px;
    background-color: #f7f7f7;
    font-weight: 500;
    justify-content: center;
}

.modal-dialog.reach-out-modal-container .custom-modal-submit-btn {
    background-color: var(--main-purple);
    color: white;
    font-size: 18px;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
    height: 100%;
}

.modal-dialog.reach-out-modal-container .note-hd {
    /* font-style: italic; */
    font-weight: 700;
}

.modal-dialog.reach-out-modal-container .custom-modal-note {
    font-size: 16px;
    color: grey;
    font-weight: 500;
    font-style: italic;
    text-align: center;
}

.modal-dialog.reach-out-modal-container .date-and-file-wrap {
    display: flex;
    gap: 10px;
    width: 100%;
}

@media (max-width: 767px) {
    .modal-dialog.reach-out-modal-container .custom-modal-textarea {
        height: 350px;
    }
    .modal-dialog.reach-out-modal-container .custom-modal-submit-btn-wrap {
        width: 100%;
    }

    .modal-dialog.reach-out-modal-container .custom-modal-submit-btn {
        width: 100%;
        justify-content: center;
    }

    .modal-dialog.reach-out-modal-container .custom-modal-attchment-btn {
        width: 100%;
    }

    .modal-dialog.reach-out-modal-container .inputDate {
        width: 100%;
    }
}

@media (max-width: 580px) {
    .modal-dialog.reach-out-modal-container .custom-modal-attchment-btn {
        font-size: 13px;
    }

    .modal-dialog.reach-out-modal-container .date-and-file-wrap {
        flex-direction: column;
    }

    .modal-dialog.reach-out-modal-container .inputDate {
        font-size: 13px;
    }
}

@media (max-width: 550px) {
    .simcha-container .simcha-text-hd-wrap .simcha-hd {
        font-size: 26px;
    }
}

@media (min-width: 1400px) {
    .simcha-text-wrap {
        line-height: 34px;
        letter-spacing: -0.634px;
        max-width: 560px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .simcha-text-wrap {
        max-width: 526px;
    }
}
