.conditions-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 70px;
    margin-bottom: 50px;
    font-family: var(--Nexa-font);
    flex-grow: 1;
}

.conditions-container .conditions-sub-container {
    max-width: var(--container-max-width);
    display: flex;
    gap: 40px;
    width: 100%;
    height: 100%;
}

.conditions-sub-container .conditions-text-wrap {
    width: 55%;
}

.conditions-sub-container .conditions-text-wrap .conditions-hd {
    font-size: var(--hd-font-size);
    font-weight: 500;
    margin-bottom: 70px;
    line-height: 72px;
}

.conditions-sub-container .conditions-text-wrap .conditions-sub-hd {
    font-size: 33px;
    font-weight: 500;
    margin-bottom: 40px;
}

.conditions-sub-container .conditions-text-wrap .conditions-text {
    font-size: 16px;
    margin-bottom: 50px;
    line-height: 25px;
}

.conditions-sub-container .conditions-text-wrap .conditions-text span {
    color: var(--main-purple);
    text-decoration: underline;
}

.conditions-img-wrap {
    width: 45%;
}
.conditions-img-wrap img {
    width: 94%;
    margin-left: 5%;
}

@media (max-width: 767px) {
    .conditions-container .conditions-sub-container {
        flex-direction: column;
        align-items: center;
    }

    .conditions-sub-container .conditions-text-wrap {
        width: 100%;
    }

    .conditions-img-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .conditions-img-wrap img {
        width: 75%;
        margin-left: 0px;
        margin-bottom: 50px;
    }
    .conditions-sub-container .conditions-text-wrap .conditions-hd {
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 40px;
    }
    .conditions-sub-container .conditions-text-wrap .conditions-sub-hd {
        font-size: 30px;
    }
    .conditions-container {
        margin-top: 50px;
    }
}
@media (max-width: 576px) {
    .conditions-sub-container .conditions-text-wrap .conditions-hd {
        font-size: 40px;
        line-height: 50px;
    }
}
/* modal styling  */

.reach-out-modal-container .modal-content {
    border-radius: 30px;
    font-family: var(--Nexa-font);
    padding: 20px 30px 30px 30px;
    height: 100% !important;
}

.reach-out-modal-container .reach-out-hd {
    font-size: 45px;
    text-align: left;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.781);
}

.reach-out-modal-container .reach-out-text {
    color: #898989;
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 20px;
    position: relative;
}

.modal-close-btn {
    /* position: absolute;
    right: 20px;
    top: 10px; */
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    /* color: black !important; */
    /* background-color: white; */
    position: absolute;
    right: 20px;
    z-index: 2;
}
.modal-close-btn div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.modal-close-btn:hover div {
    background-color: #f2f2f2;
    border-radius: 50%;
}

.reach-out-modal-container .reach-modal-input-wrap {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.reach-out-modal-container .reach-text-input-wrap {
    display: flex;
    /* gap: 30px; */
}

.modal-dialog.reach-out-modal-container {
    /* top: -2%; */
    max-width: 800px !important;
}

.reach-out-modal-container .reach-input {
    border: 2px solid #d8d7d7d5;
    border-radius: 15px;
    padding: 25px 0px 25px 20px;
}
.reach-out-modal-container .reach-input:focus {
    border: 2px solid #d8d7d7d5;
}

.reach-out-modal-container .reach-out-text-area {
    border: 2px solid #d8d7d7d5;
    border-radius: 15px;
    height: 260px;
    padding-left: 20px;
}

.reach-out-modal-container .reach-input::placeholder {
    color: #d8d7d7d5;
    font-size: 16px;
}

.reach-out-modal-container .reach-out-text-area::placeholder {
    color: #d8d7d7d5;
    font-size: 16px;
}

.reach-out-modal-container .textarea-icon-wrap {
    position: relative;
}

.reach-out-modal-container .send-icon-wrap {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.reach-out-modal-container .reach-out-btn-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
}
.reach-out-modal-container .reach-out-btn {
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: 2px solid var(--main-purple);
    padding: 10px 10px;
    /* width: 100%; */
    gap: 10px;
    align-items: center;
    color: var(--main-purple);
    border-radius: 15px;
    font-size: 20px;
}
.reach-out-modal-container .reach-out-btn:hover {
    border: 2px solid black;
    color: black;
}

@media (max-width: 767px) {
    .reach-out-modal-container .modal-content {
        border-radius: 30px;
        font-family: var(--Nexa-font);
        padding: 20px 10px 30px 10px;
        height: 100% !important;
    }

    .modal-dialog.reach-out-modal-container {
        max-width: 600px !important;
    }

    .reach-out-modal-container .reach-out-hd {
        font-size: 25px;
        text-align: center;
    }

    .reach-out-modal-container .reach-out-text {
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
    }

    .reach-out-modal-container .reach-modal-input-wrap {
        gap: 25px;
    }

    .reach-out-modal-container .reach-out-btn-wrap {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
    .reach-out-modal-container .reach-out-btn {
        /* display: flex;
        background-color: transparent;
        border: 2px solid var(--main-purple);
        padding: 10px 10px;
        gap: 10px;
        align-items: center;
        color: var(--main-purple);
        border-radius: 15px; */
        /* padding: 10px 74px; */
        font-size: 12px;
        /* width: 100%; */
    }
}
@media (max-width: 576px) {
    .reach-out-modal-container .reach-out-btn {
        width: 100%;
    }
}
