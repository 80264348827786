@font-face {
    font-family: "NexaRegular";
    src: url("./assets/fonts/Nexa-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #fff !important;
    color: #000 !important;
    font-family: "NexaRegular", sans-serif;
}

.product-detail-wrapper .preview-container-btn {
    width: 84px !important;
}

.product-detail-wrapper .preview-container-btn i.fa-eye {
    top: 0px !important;
}

.container {
    max-width: 1406px;
    margin: 0 auto;
    padding: 0 20px;
}

.section-title {
    color: black;
    font-size: 60px;
    line-height: 68px;
    font-weight: 700;
}

.section-subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.648px;
}

.date-input-container {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border-radius: 15px;
    padding: 12px 16px;
    gap: 3px 10px;
    justify-content: center;
}

.date-trigger {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.calendar-icon {
    width: 20px;
    height: 20px;
}

.date-label {
    font-size: 17px;
    font-weight: 500;
    color: #000;
}

.date-input {
    border: none;
    background: transparent;
    font-size: 16px;
    color: #666;
    cursor: pointer;
    padding-left: 92px;
}

.date-input::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.date-input::-moz-calendar-picker-indicator {
    opacity: 0;
}

.date-input::-ms-clear {
    display: none;
}

.date-input::placeholder {
    color: #999;
}

.date-input::-webkit-datetime-edit {
    color: #d8d8d8;
}

.date-input::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
}

.date-input::-webkit-datetime-edit-text {
    padding: 0 2px;
}

.date-input-container input:before {
    content: "Due Date:";
    font-weight: 500;
    position: absolute;
    left: 0;
    color: #000;
    font-size: 17px;
    border: 0;
}

/* Add this class for filled date inputs */
.date-input.filled-date,
.date-input.filled-date::-webkit-datetime-edit {
    color: var(--main-purple);
    font-weight: 500;
}

@media (max-width: 1024px) {
    .section-title {
        font-size: 48px;
        line-height: 1.1;
        font-weight: 500;
        text-align: center;
    }
    .section-subtitle {
        font-weight: 400;
        text-align: center;
        font-size: 20px;
        letter-spacing: -0.8px;
    }
}

@media (max-width: 767px) {
    .section-title {
        font-size: 28px;
        line-height: 1.1;
        font-weight: 600;
    }
    .section-subtitle {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: normal;
    }
}

@media (max-width: 580px) {
    .date-label,
    .date-input::-webkit-datetime-edit {
        font-size: 13px;
    }
    .date-input-container input:before {
        position: static;
    }
    .date-input {
        padding-left: 0px;
    }
}
