.product-detail-wrapper {
    padding: 30px 100px;
    /* background-color: #00000007; */
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
}

.pd-flex-container {
    display: flex;
}
.canvas-container {
    width: 100% !important;
}
.pd-right-column {
    width: 50%;
    min-width: 380px;
    padding: 0 30px;
    padding-top: 30px;
    margin-left: 30px;
}
/* ::-webkit-scrollbar {
    width: 13px;
    background: #eeeeee;
}

::-webkit-scrollbar-thumb {
    background: #8e8e8e;
    border: 5px solid #e7e7e7;
    border-radius: 10px;
} */

.pd-nameprice h4 {
    font-size: 20px;
    margin-bottom: 5px;
}

.pd-nameprice h3 {
    font-size: 20px;
}

.pd-go-back {
    margin-bottom: 10px;
    cursor: pointer;
    width: fit-content;
}

.pd-go-back i {
    color: #bf5ade;
    font-size: 18px;
}

.pd-go-back span {
    font-size: 18px;
    margin-left: 10px;
    font-weight: bolder;
    color: #000;
}

#pd-hr {
    height: 3px;
    width: 100%;
    border-radius: 5px;
    background: #e3e3e3;
    margin-top: 30px;
    margin-bottom: 25px;
    border: none;
}

.pd-checkboxes {
    margin-bottom: 10px;
}

.pd-qr-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.pd-checkbox label {
    color: #333;
    font-weight: bold;
}

.pd-checkbox input {
    margin-right: 10px;
}

/* test */
/* #test-resize{

} */

.text-inside-wrapper {
    border: 1px solid red;
}

/* qr code input */
.qr-input-wrapper input {
    border-radius: 5px;
    padding: 5px 8px;
    margin-left: 7px;
    border: 1px solid #a251ba;
}

#btn-qr {
    border: 1px solid #883688;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: white;
    background: #a251ba;
    font-size: 12px;
    width: 100%;
}

.pd-nameprice {
    margin-top: 25px;
}

.pd-bottom-btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 25px;
}

.pd-checkbox {
    display: flex;
    align-items: center;
}
