.category-container {
    position: relative;
    background-color: #fdf8fd;
    margin-top: 10px;
    padding-bottom: 40px;
    font-family: "Nexa", sans-serif;
}

.category-container::before {
    z-index: 0;
    content: "";
    width: 100%;
    height: 450px;
    background: url("../../../assets/images/hhh.svg") no-repeat;
    top: -450px;
    left: 0;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.category-container::after {
    z-index: -1;
    content: "";
    width: 100%;
    height: 400px;
    background: url("../../../../src/assets/simchaInvitaion/bg-img.svg") no-repeat;
    bottom: -400px;
    left: 0;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.category-container .category-wrap {
    max-width: 1398px;
    padding: 0px 16px;
}

.category-container .category-hd {
    font-size: var(--hd-font-size);
    margin-top: 20px;
    color: black;
    line-height: 72px;
    font-weight: var(--font-weight-600);
    margin-bottom: 0px;
}

.category .text-center .section-title {
    margin: 12px 0px 44px;
}

.section-subtitle {
    margin: 48px 0px 78px 0px;
}
@media (min-width: 1550px) {
    .category-container::before {
        height: 500px;
        top: -500px;
    }
}

@media (max-width: 1024px) {
    .category-container {
        margin-top: 150px;
        padding-bottom: 70px;
    }
    .category-container::before {
        height: 250px;
        top: -200px;
    }
    .category-container .text-center .category-hd {
        font-size: 34px;
        margin: 50px 0px 44px;
        line-height: 50px;
    }
    .category-container::after {
        height: 250px;
        bottom: -200px;
    }
    .section-subtitle {
        margin: 48px 0px 0px 0px;
    }
}

@media (max-width: 767px) {
    .category-container::before {
        top: -100px;
        height: 100px;
        z-index: 0;
    }
    .category-container::after {
        bottom: -100px;
        height: 100px;
    }
    .category-container .text-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        width: 100%;
        margin-top: 30px;
    }
    .category-container .section-subtitle {
        margin: 0px;
        width: 80%;
        font-size: 12px;
        line-height: 18px;
    }
    .category-container .text-center .category-hd {
        /* width: 80%; */
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 20px;
    }
    .category-container .category-wrap {
        padding: 0px 16px;
    }
}
@media (max-width: 550px) {
    .category-container .text-center .category-hd {
        font-size: 26px;
        max-width: 300px;
    }
    .category-container .text-center {
        margin-top: 10px;
    }
    .category-container {
        margin-top: 100px;
    }
    .category-container .section-subtitle {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .category {
        margin-top: 110px;
    }
}

@media (max-width: 374px) {
    .category-container .category-wrap {
        padding: 0px 6px;
    }
}

@media only screen and (min-width: 2350px) {
    .category-container::before {
        height: 700px;
        top: -700px;
        left: -180px;
        width: 110%;
    }
    .category-container::after {
        height: 700px;
        bottom: -600px;
        left: -180px;
        width: 110%;
        background-position: top left;
        z-index: -1;
    }
}
