.sub-menu {
    padding: 40px 35px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    min-width: 220px;
    position: absolute;
    top: -20px;
    left: 100%;
    transform: translateX(-20px);
    transition: visibility 0.3s ease;
    visibility: hidden;
    color: black;
    text-align: left;
    white-space: nowrap;
}

.simchas-drop-down:hover .sub-menu {
    visibility: visible;
}

.sub-menu .sub-menu-link {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
}

.sub-menu .sub-menu-link:last-of-type {
    margin-bottom: 0;
}

.sub-menu .sub-menu-link:hover {
    color: var(--nav-link-hover-color);
    transition: 0.5s;
    font-weight: var(--font-weight-600);
}

.sub-menu .text-icon-true {
    font-size: 20px;
    font-weight: 400;
}
