:root {
    --main-purple: #af40f7;
    --Nexa-font: "Nexa", sans-serif;
    --hd-font-size: 60px;
    --text-font-size: 24px;
    --font-weight-600: 600;
    --nav-link-hover-color: rgba(128, 128, 128, 0.521);
    --container-max-width: 1398px;
}

/* some new global styles */
* {
    scroll-behavior: smooth;
}

video {
    width: 100%;
    vertical-align: top;
}

/* a {
    text-decoration: none;
} */

/* body {
} */

/* nexa font */

@font-face {
    font-family: "FbFahrenheit2";
    font-display: auto;
    font-weight: 400;
    src: url("../fonts/FbFahrenheit-Regular.otf") format("OpenType");
}
@font-face {
    font-family: "FbFahrenheit";
    font-display: auto;
    font-weight: 700;
    src: url("../fonts/FbFahrenheit-Bold.otf") format("OpenType");
}
@font-face {
    font-family: "FbFahrenheit";
    font-display: auto;
    font-weight: 800;
    src: url("../fonts/FbFahrenheit-Black.otf") format("OpenType");
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaThin.otf") format("OpenType");
    font-display: auto;
    font-weight: 100;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaLight.otf") format("OpenType");
    font-display: auto;
    font-weight: 200;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaBook.otf") format("OpenType");
    font-display: auto;
    font-weight: 300;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaRegular.otf") format("OpenType");
    font-display: auto;
    font-weight: 400;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaBold.otf") format("OpenType");
    font-display: auto;
    font-weight: 500;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaBold.otf") format("OpenType");
    font-display: auto;
    font-weight: 600;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaHeavy.otf") format("OpenType");
    font-display: auto;
    font-weight: 700;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaHeavy.otf") format("OpenType");
    font-display: auto;
    font-weight: 800;
}
@font-face {
    font-family: "Nexanew";
    src: url("../fonts/NexaHeavy.otf") format("OpenType");
    font-display: auto;
    font-weight: 900;
}

@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaThin.otf") format("OpenType");
    font-display: auto;
    font-weight: 100;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaLight.otf") format("OpenType");
    font-display: auto;
    font-weight: 200;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaBook.otf") format("OpenType");
    font-display: auto;
    font-weight: 300;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaRegular.otf") format("OpenType");
    font-display: auto;
    font-weight: 400;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaBold.otf") format("OpenType");
    font-display: auto;
    font-weight: 500;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaBold.otf") format("OpenType");
    font-display: auto;
    font-weight: 600;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaHeavy.otf") format("OpenType");
    font-display: auto;
    font-weight: 700;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaHeavy.otf") format("OpenType");
    font-display: auto;
    font-weight: 800;
}
@font-face {
    font-family: "Nexanew2";
    src: url("../fonts/NexaHeavy.otf") format("OpenType");
    font-display: auto;
    font-weight: 900;
}

/* light */
@font-face {
    font-family: "fbjabutinski-Light";
    src: url("../fonts/FbJabutinski-Light.otf") format("OpenType");
    font-display: auto;
    font-weight: 100;
}
@font-face {
    font-family: "fbjabutinski-Light";
    src: url("../fonts/FbJabutinski-Light.otf") format("OpenType");
    font-display: auto;
    font-weight: 200;
}
@font-face {
    font-family: "fbjabutinski-Light";
    src: url("../fonts/FbJabutinski-Light.otf") format("OpenType");
    font-display: auto;
    font-weight: 300;
}

/* medium */
@font-face {
    font-family: "fbjabutinski-Medium";
    src: url("../fonts/FbJabutinski-Medium.otf") format("OpenType");
    font-display: auto;
    font-weight: 400;
}
@font-face {
    font-family: "fbjabutinski-Medium";
    src: url("../fonts/FbJabutinski-Medium.otf") format("OpenType");
    font-display: auto;
    font-weight: 500;
}
@font-face {
    font-family: "fbjabutinski-Medium";
    src: url("../fonts/FbJabutinski-Medium.otf") format("OpenType");
    font-display: auto;
    font-weight: 600;
}

/* regular */
@font-face {
    font-family: "fbjabutinski-Regular";
    src: url("../fonts/FbJabutinski-Regular.otf") format("OpenType");
    font-display: auto;
    font-weight: 400;
}
@font-face {
    font-family: "fbjabutinski-Regular";
    src: url("../fonts/FbJabutinski-Regular.otf") format("OpenType");
    font-display: auto;
    font-weight: 500;
}
@font-face {
    font-family: "fbjabutinski-Regular";
    src: url("../fonts/FbJabutinski-Regular.otf") format("OpenType");
    font-display: auto;
    font-weight: 600;
}

/* bold */
@font-face {
    font-family: "fbjabutinski-Bold";
    src: url("../fonts/FbJabutinski-Bold.otf") format("OpenType");
    font-display: auto;
    font-weight: 700;
}
@font-face {
    font-family: "fbjabutinski-Bold";
    src: url("../fonts/FbJabutinski-Bold.otf") format("OpenType");
    font-display: auto;
    font-weight: 800;
}
@font-face {
    font-family: "fbjabutinski-Bold";
    src: url("../fonts/FbJabutinski-Bold.otf") format("OpenType");
    font-display: auto;
    font-weight: 900;
}

@font-face {
    font-family: "Nexa";
    src: url("../fonts/Nexa-Regular.woff2") format("opentype");
    font-display: auto;
    font-weight: 100;
}
@font-face {
    font-family: "Nexa";
    src: url("../fonts/NexaLight.otf") format("opentype");
    font-display: auto;
    font-weight: 200;
}
@font-face {
    font-family: "Nexa";
    src: url("../fonts/Nexa-Regular.woff2") format("woff2");
    font-display: auto;
    font-weight: 300;
}
@font-face {
    font-family: "Nexa";
    src: url("../fonts/Nexa-Regular.woff2") format("woff2");
    font-display: auto;
    font-weight: 400;
}
@font-face {
    font-family: "Nexa";
    src: url("../fonts/Nexa-Book.woff2") format("woff2");
    font-display: auto;
    font-weight: 500;
}

@font-face {
    font-family: "Nexa";
    src: url("../fonts/Nexa-Bold.woff2") format("woff2");
    font-display: auto;
    font-weight: 600;
}
@font-face {
    font-family: "Nexa";
    src: url("../fonts/Nexa-Bold.woff2") format("woff2");
    font-display: auto;
    font-weight: 700;
}
@font-face {
    font-family: "Nexa";
    src: url("../fonts/NexaHeavy.otf") format("opentype");
    font-display: auto;
    font-weight: 800;
}
@font-face {
    font-family: "Nexa";
    src: url("../fonts/NexaHeavy.otf") format("opentype");
    font-display: auto;
    font-weight: 900;
}

body {
    font-family: "Nexanew";
    font-weight: 400;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-full {
    flex: 1;
    height: 100%;
    background-color: #f7f7f7;
}

.centered-fullfill {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-width {
    max-width: var(--container-max-width);
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin: 0 auto;
    width: 100%;
}
.custom-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.custom-row > * {
    width: auto;
}
.custom-input {
    height: 61.75px !important;
    padding: calc(0.375em - 1px) calc(0.625em - 1px) !important;
    border-radius: 14px !important;
    border: 1px solid rgb(219, 219, 219) !important;
    box-shadow: rgba(10, 10, 10, 0.1) 0px 1px 2px inset !important;
}
.side-panel-loader {
    height: 450px;
    background-color: rgb(237, 234, 234);
    border-radius: 26px;
}
.input-gap {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 25px;
}

/* backgrounds */
.site-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 73px;
    overflow-x: hidden;
}

#site-content {
    z-index: 0;
}

/* .site-content > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.site-content > div > div:first-child {
    flex-grow: 1;
} */

.padding-6 {
    padding: 60px;
}

.centered {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-light-blue {
    background-color: #faf2ff !important;
}

/* virtual keyboard overwrite */
.hg-theme-default .hg-button {
    height: 26px !important;
    color: black !important;
    transition: 0.1s !important;
}

.hg-theme-default .hg-button:hover {
    background-color: #d58debc5 !important;
}

.hg-theme-default .hg-row .hg-button:not(:last-child) {
    margin-right: 2px !important;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".com"] {
    display: none !important;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
    display: none !important;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="{space}"]:before {
    content: "Space" !important;
}

/* shopping cart page and wishlist page */
.product-remove .remove {
    display: flex;
    justify-content: center;
}

.product-remove i {
    font-size: 24px;
    cursor: pointer;
}

.add_to_cart_button_wishlist {
    width: 100%;
    text-align: center;
    padding: 4px;
    border-radius: 5px;
    background-color: #d58deb;
    cursor: pointer;
    font-weight: bold;
}

/* css template overwrite */
.content-wrapper.section-pt.section-pb {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.all-flex-to-full-height {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.svg-white svg {
    color: #fff;
}
.svg-black svg {
    color: #000;
}

.coupon-code-form-group .form-control {
    width: 1% !important;
    border-radius: 15px 0 0 15px !important;
}

.coupon-code-form-group .coupon-code-apply-btn {
    background-color: var(--main-purple);
    color: white;
    font-size: 16px;
    border-radius: 10px !important;
    border-left: none !important;
}

/* general */
/* body::-webkit-scrollbar {
    width: 10px;
    display: none;
  } */

/* .inputs-list-container::-webkit-scrollbar, 
.products-grid-container::-webkit-scrollbar
{
    width: 10px;
  } */

/* Track */
/* .inputs-list-container::-webkit-scrollbar-track,
 .products-grid-container::-webkit-scrollbar-track
  {
    background: #e7e7e7;
    border-radius: 4px;
  }
   */
/* Handle */
/* .inputs-list-container::-webkit-scrollbar-thumb,
.products-grid-container::-webkit-scrollbar-thumb
  {
    background: #a8a8a8;
    border-radius: 5px;
  } */

/* Handle on hover */
/* .inputs-list-container::-webkit-scrollbar-thumb:hover,
  .products-grid-container::-webkit-scrollbar-thumb:hover
  {
    background: #919191;
  } */

/* inputs */
/* .inputs-list-container {
    font-family: "fbjabutinski-Light";
    font-weight: 300;
} */

.english-input {
    font-family: "Nexanew2" !important;
    font-weight: 300 !important;
}

.card-wrapper {
    display: grid !important;
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 236px)) !important;
    gap: 20px !important;
    width: 100% !important;
    box-sizing: border-box !important;
}
.card-wrapper > div,
.card-wrapper > div img {
    max-width: 250px;
    width: 100% !important;
}

.not-found {
    width: auto;
}

.h-60vh {
    height: 60vh;
}

.cursor-pointer {
    cursor: pointer;
}

input::placeholder {
    color: #999 !important;
    font-weight: 400 !important;
}

@media screen and (max-width: 1400px) {
    .card-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(200px, 215px)) !important;
    }
}

@media screen and (max-width: 1336px) {
    .card-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(200px, 236px)) !important;
    }
}

@media screen and (max-width: 1120px) {
    .card-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(200px, 215px)) !important;
    }
}

@media (max-width: 1024px) {
    .site-content {
        position: unset !important;
    }
}
@media (max-width: 767px) {
    .not-found {
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 576px) {
    .card-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(150px, 180px)) !important;
    }
    .card-wrapper {
        gap: 10px !important;
    }
}

@media (max-width: 550px) {
    .container-width {
        max-width: 345px;
    }
    .product-list-class {
        max-width: 100%;
    }
}

/* canvas overwrite for mobile */
@media screen and (max-width: 450px) {
    .product-detail-wrapper .pd-checkboxes {
        z-index: 1;
    }
    #inputs-list-wrapper {
        z-index: 1;
    }
    .k-toggle {
        display: none !important;
    }
    .card-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr)) !important;
    }
    .flex-xs-wrap {
        flex-wrap: wrap !important;
    }
}
