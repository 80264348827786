.footer-container {
    background-color: black;
    height: 94px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-container .footer-sub-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--container-max-width);
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 25px !important;
}

.footer-container p {
    text-align: center;
    color: white;
    margin: 0px;
    font-size: 16px;
}
@media (max-width: 1024px) {
    .footer-container p {
        font-size: 14px;
    }
}

@media (max-width: 550px) {
    .footer-container .footer-sub-container {
        justify-content: center;
        flex-direction: column-reverse;
    }
    .footer-container p {
        font-size: 10px;
        margin-top: 4px;
    }
}
