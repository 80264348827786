.cart-menu-container {
    position: absolute;
    width: 330px;
    /* height: 500px; */
    scrollbar-width: none;
    border-radius: 20px;
    background-color: white;
    top: 55px;
    right: 32px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.438);
    -webkit-box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.438);
    -moz-box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.438);
    padding: 10px 20px;
}

.cart-menu-container.display-none {
    display: none;
}

.cart-menu-container.hidden {
    animation-name: cartMenuClose;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    /* display: none; */
}

.cart-menu-container.active {
    display: flex;
    flex-direction: column;
    right: 32px;
    animation-name: cartMenuOpen;
    animation-duration: 0.3s;
}

.cart-menu-container .close-cart-menu-icon {
    margin: 5px 0;
    cursor: pointer;
}

.cart-menu-container .close-cart-menu-icon > div {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.cart-menu-container .close-cart-menu-icon:hover >div {
    background-color: #f2f2f2;
    border-radius: 50%;
}

.cart-menu-container .shopping-cart-hd {
    font-size: 24px;
    font-weight: 500;
    color: black;
    margin-bottom: 20px;
}

.cart-menu-container .cart-menu-sub-container {
    scrollbar-width: none;
    max-height: 430px;
    overflow-y: scroll;
    position: relative;
}

.cart-menu-container .cart-menu-item-wrap {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
    border: 1px solid lightgray;
    padding: 10px 14px;
    border-radius: 15px;
}

.cart-menu-container .cart-menu-data-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.cart-menu-container .cart-menu-img {
    width: 90px;
    height: 90px;
    border-radius: 10px;
}

.cart-menu-container .cart-menu-item-name {
    margin: 0px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.cart-menu-container .cart-menu-item-price {
    margin: 0px;
    margin-top: 6px;
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.cart-menu-container .cart-menu-item-data {
    font-size: 12px;
    margin: 0px;
    color: rgb(80, 80, 80);
    line-height: 14px;
    font-weight: 500;
}

.cart-menu-container .cart-menu-icon-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cart-menu-icon-wrap .cart-menu-eye-icon {
    border: 0.5px solid lightgray;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    padding-bottom: 4px;
    cursor: pointer;
}

.cart-menu-container .cart-menu-icon-wrap .cart-menu-edit-icon {
    border: 0.5px solid lightgray;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    padding-bottom: 4px;
    cursor: pointer;
}

.cart-menu-container .cart-menu-icon-wrap .cart-menu-delete-icon {
    border: 0.5px solid lightgray;
    border-radius: 6px;
    height: 20px;
    font-size: 12px;
    color: red;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cart-menu-container .cart-menu-btn-wrap {
    /* position: absolute; */
    bottom: 18px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    background-color: var(--main-purple);
    cursor: pointer;
}

.cart-menu-container .cart-menu-btn-wrap .cart-menu-btn {
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-transform: none;
}
@media (max-width: 576px) {
    .cart-menu-container {
        right: 17px;
    }
}
@media (max-width: 375px) {
    .cart-menu-container {
        width: 295px;
    }
}

@keyframes cartMenuOpen {
    0% {
        top: 50px;
        opacity: 0;
    }

    100% {
        top: 55px;
        opacity: 1;
    }
}

@keyframes cartMenuClose {
    0% {
        /* display: none; */
        top: 55px;
        opacity: 1;
    }

    100% {
        top: 45px;
        opacity: 0;
        display: none;
    }
}
