.hebrew-date-wrapper{
    position: relative;
    margin-bottom: 10px;
}

.hebrew-calendar-type-selection{
    display: flex;
}

.hebrew-calendar-type-selection div{
    display: flex;
}

.hebrew-calendar-type-selection label{
    margin-right: 10px;
    margin-bottom: 2px;
    margin-top: 3px;
}

.date-fixed-wrapper{
    background-color: #f8f8f8;
    z-index: 999;
    margin-bottom: 12px;
    margin-left: 10px;
    margin-right: 10px;
    position: static !important;
}
