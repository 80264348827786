.song-item-container{
    display: flex;
    align-items: center;
    background-color: #eeeeee;
    margin-bottom: 11px;
    border-radius: 3em;
    position: relative;
}

.song-playpause-btn{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background-color: #BF5ADE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.play-img{
    width: 22px;
}

.pause-img{
    width: 20px;
}

.audio-line{
    stroke-dasharray: 157;
    stroke-dashoffset: 157;
    animation-play-state: paused;
}

.song-radio-btn{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: auto;
    right: 18px;
    transform: translateY(-50%);
}

.song-radio-btn.active{
    background-color: #BF5ADE;
    /* border-color: transparent; */
    border-color: #BF5ADE;
}

.song-radio-btn img{
    width: 13px;
}

.song-bar-and-name{
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    position: relative;
    overflow: hidden;
    height: 40px;
}

.figure-chart{
    display: block;
    transition: opacity .4s linear;
    margin: 28px 0 0 0;
}

.song-bar-and-name svg{
    border-radius: 0;
    background: #dadada;
}

.audio-line{
    fill: transparent;
    stroke: #BF5ADE;
    stroke-width: 4;
    stroke-dasharray: 160;
    stroke-dashoffset: 160;
}

.song-name-title-span{
    font-weight: 300;
}

.song-name-title-div{
    width: 100%;
    overflow: hidden;
    position: absolute;
    width: 500px;
    color: #000;
}

.song-item-container:hover .song-name-title-div.txt-long{
    animation-name: text-to-left;
    animation-timing-function: linear;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

/* animations */

@keyframes text-to-left{
    0%{
        left: 105%;
    }
    100%{
        left: -115%;
    }
}

/* animation name defined on songItem.jsx */
@keyframes fill-song-bar{
    0%{
        stroke-dashoffset: 160;
    }
    100%{
        stroke-dashoffset: 0;
    }
}