/* .songs-container{

} */

.songs-container{
    position: relative;
}

.audio{
    height: 35px;
}

.song-item{
    padding: 10px;
    padding-top: 5px;
}

.song-item label{
    color: black;
}

.song-input-wrapper{
    display: flex;
    align-items: center;
}

.song-input-wrapper input{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.audios-container{
    position: absolute;
    left: 0;
    border: 1px solid #af40f7;
    border-radius: 20px;
    z-index: 99;
    background-color: #fff;
    padding: 15px 20px 21px 25px;
    z-index: 200;
    bottom: 45px;
    max-width: 346px;
    width: 346px;
}

.audios-container-close{
   width: 100%;
   display: flex;
   justify-content: flex-end;
   margin-bottom: 10px;
}

.audios-container-close-btn{
    width: 28px;
    cursor: pointer;
}

.audios-container-close-btn img{
    width: 100%;
}

.audios-container-song-list{
    max-height: 284px;
    overflow-y: auto;
}

.selected-song{
    margin-bottom: 10px;
    margin-top: -5px;
}

.selected-song-name{
    color: #aa4cc6;
    font-family: 'Nexanew', sans-serif;
    font-weight: 600;
}

.selected-song span{
    font-weight: 300;
}