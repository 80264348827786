.offcanvas .offcanvas-header {
    width: 100%;
    border-bottom: 2px solid rgba(128, 128, 128, 0.384);
    padding-top: 40px;
}

.offcanvas-container .offcanvas-button {
    cursor: pointer;
}

.offcanvas-header .offcanvas-links-wrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.offcanvas-header .offcanvas-header-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offcanvas-header .offcanvas-header-links.heart-wrap {
    position: relative;
}

.offcanvas-header .offcanvas-link {
    text-decoration: none;
    color: black;
}
.offcanvas-header .btn-close {
    top: 15px;
    right: 15px;
}

.offcanvas-body .offcanvas-menu {
    display: flex;
    gap: 10px;
}

.offcanvas-body .offcanvas-menu:hover {
    text-decoration: underline;
    cursor: pointer;
}

.offcanvas-body .offcanvas-menu-wrap {
    display: flex;
    padding-left: 25px;
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
}
