.header-container {
    width: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid rgba(128, 128, 128, 0.274);
    min-height: 60px;
    background-color: white;
    font-family: var(--Nexa-font);
    z-index: 10;
    position: fixed;
}

.header-container .header-sub-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--container-max-width);
    z-index: 10;
    padding: 18.5px 26px;
}

.responsive-sub-container {
    display: none;
}

.header-container .logo-wrap {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}

.header-container .logo {
    width: 280px;
}

.header-container .beta-logo {
    background: #af40f717;
    color: var(--main-purple);
    font-size: 10px;
    border: 1px solid var(--main-purple);
    border-radius: 7px;
    padding: 0px 5px 0px 5px;
    font-weight: normal;
    height: 19px;
    margin: 0px;
    display: flex;
    align-items: center;
    letter-spacing: 0.7px;
}

.responsive-sub-container .beta-logo-res {
    height: 26px;
    padding: 0px 12px 0px 12px;
    border-radius: 10px;
    margin-top: 3px;
}

.header-sub-container .links-icons-wrap {
    padding-right: 0px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 2px;
    justify-content: end;
    flex-grow: 1;
}

.header-container .nav-icon:hover {
    opacity: 0.5;
    cursor: pointer;
}

.header-container .auth-link {
    padding: 0px 6px 0px 14px;
}

.header-container .icons-wrap {
    display: flex;
    align-items: center;
    gap: 13px;
}

.header-sub-container .links-wrap {
    display: flex;
    padding-right: 4%;
}

.header-sub-container .links-wrap .link {
    text-decoration: none;
    color: black;
    font-size: 13px;
    font-weight: var(--font-weight-600);
    letter-spacing: 0px;
    padding: 5px 0;
    margin: 0 20px;
}

.header-sub-container .links-wrap .link.active {
    color: var(--nav-link-hover-color);
 }


.header-sub-container .links-wrap .link:hover {
    color: var(--nav-link-hover-color);
    transition: 0.5s;
    font-weight: var(--font-weight-600);
}

.header-sub-container .ocassions-drop-down {
    padding: 30px 30px;
    border-radius: 30px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.123);
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 30px;
    left: -19px;
    color: black;
    z-index: 10;
    background-color: white;
}

.header-sub-container .ocassions-drop-down.display-none {
    display: none;
}

.header-sub-container .ocassions-drop-down.active {
    animation-name: ocassionMenuOpen;
    animation-duration: 0.3s;
}

.header-sub-container .ocassions-drop-down.hidden {
    animation-name: ocassionMenuClose;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.header-sub-container .ocassions-drop-down .drop-down-link:hover {
    color: var(--nav-link-hover-color);
    transition: 0.5s;
    font-weight: var(--font-weight-600);
}

.header-sub-container .ocassions-link {
    position: relative;
    margin: 0 !important;
    text-align: center;
    width: 80px;
}

.header-sub-container .ocassions-link:hover .ocassions-drop-down {
    visibility: visible;
}

.header-sub-container .simchas-link {
    position: relative;
    margin: 0 !important;
    text-align: center;
    width: 80px;
}

.responsive-sub-container .logo-res {
    width: 250px;
}

.header-container .heart-icon {
    position: relative;
}

.header-container .cart-icon {
    position: relative;
}

.header-container .cart-length {
    /* position: absolute;
    top: -6px;
    right: -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: red;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; */
    position: absolute;
    right: -8px;
    top: -8px;
    border-radius: 50%;
    width: 1.6em;
    height: 1.6em;
    font-size: 8px;
    text-align: center;
    background-color: #E6345D;
    color: rgb(255, 255, 255);
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Nexa-font);
    padding: 8px;
}

.header-container .wishlist-length {
    position: absolute;
    right: -6px;
    top: -8px;
    border-radius: 50%;
    width: 1.6em;
    height: 1.6em;
    font-size: 8px;
    text-align: center;
    background-color: #E6345D;
    color: rgb(255, 255, 255);
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Nexa-font);
    padding: 8px;
}

.drop-down-link {
    cursor: pointer;
}

.links-wrap .menu-item:hover, .sub-menu .sub-menu-item:hover {
    color: var(--nav-link-hover-color);
    transition: 0.5s;
    font-weight: var(--font-weight-600);
}

@keyframes ocassionMenuOpen {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes ocassionMenuClose {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

@media (min-width: 1370px) {
    .header-container {
        display: flex;
        justify-content: center;
    }
    /* .header-container .header-sub-container {
        width: 70%;
    } */
}
@media (max-width: 1200px) {
    .header-sub-container .links-wrap {
        padding-right: 10px;
    }
    .header-container .icons-wrap {
        gap: 10px;
    }
    .header-sub-container .links-wrap .link {
        margin: 0 10px;
    }
}

@media (max-width: 1024px) {
    .header-container {
        border: 3px solid rgba(128, 128, 128, 0.384);
        padding: 20px 5px;
        position: sticky;
        top: 0;
    }
    .header-container .responsive-sub-container {
        display: flex;
        justify-content: space-between;
        width: 88%;
    }
    .header-container .header-sub-container {
        display: none;
    }
}
@media (max-width: 767px) {
    .header-container .logo {
        width: 150px;
    }

    .header-container {
        border: unset;
        border-bottom: 2px solid rgba(128, 128, 128, 0.384);
    }

    .responsive-sub-container .beta-logo-res {
        height: 19px;
        padding: 0px 5px 0px 5px;
        border-radius: 8px;
        margin-top: 3px;
        font-size: 8px;
    }
}
@media (max-width: 550px) {
    .header-container .responsive-sub-container {
        width: 95%;
    }
    .header-container .logo-wrap {
        gap: 10px;
    }
    .responsive-sub-container .beta-logo-res {
        height: 15px;
        border-radius: 5px;
        font-size: 7px;
    }
}
.links-icons-wrap .links-wrap .header-pining {
    text-align: center;
    font-size: 13px;
    background-color: var(--main-purple);
    color: white;
    padding: 5px 10px 5px 10px !important;
    border-radius: 5px;
    font-weight: 400 !important;
    border-radius: 10px;
}

.links-icons-wrap .links-wrap .header-pining:hover {
    color: white;
}
.header-pining-p {
    padding-right: 4px;
}
