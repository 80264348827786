.autoComplete_wrapper{
    height: 0;
    position: inherit;
    display: block;
}

.autoComplete_wrapper ul{
    overflow: hidden;
    width: 95%;
}

.autoComplete_wrapper ul li{
    text-align: center;
}

#inputs-list-wrapper{
    z-index: 1;
}
