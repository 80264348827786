.checkout-container .checkout-sub-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 20px;
    margin-top: 60px;
    gap: 30px;
    font-family: var(--Nexa-font);
}

.checkout-container .checkout-sub-container .order-summary-container {
    width: 60%;
}

.checkout-sub-container .order-summary-container .order-summary-hd {
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 20px;
    color: black;
}

.checkout-container .checkout-sub-container .cart-items-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.checkout-container .checkout-sub-container .cart-item {
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.411);
    gap: 50px;
    padding: 35px 20px 35px 30px;
    border-radius: 20px;
    width: 100%;
}

.checkout-container .checkout-sub-container .cart-data-container {
    width: 100%;
}

.checkout-sub-container .cart-item-img {
    border-radius: 10px;
    min-width: 120px;
    height: 150px;
}

.checkout-sub-container .cart-item-hd {
    font-weight: normal;
    font-size: 18px;
    color: black;
}

.checkout-sub-container .cart-item-data {
    color: grey;
    margin: 0px;
    font-size: 18px;
}

.checkout-sub-container .cart-item-name {
    font-size: 22px;
    margin: 0px;
    /* margin-bottom: 5px; */
    font-weight: 600;
}

.checkout-sub-container .price-and-icons-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.checkout-sub-container .cart-item-price {
    font-size: 22px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 0px;
}

.checkout-sub-container .cart-icon-wrap {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.checkout-sub-container .cart-icon-wrap .cart-delete-icon {
    font-size: 17px;
    color: #d81e5e;
    border: 1px solid rgba(128, 128, 128, 0.411);
    padding: 2px 7px;
    border-radius: 5px;
    cursor: pointer;
}

.checkout-sub-container .cart-icon-wrap .cart-eye-icon {
    /* font-size: 17px; */
    color: black !important;
    border: 1px solid rgba(128, 128, 128, 0.411);
    padding: 0px 7px 4px 7px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.checkout-sub-container .cart-icon-wrap .cart-edit-icon {
    font-size: 13px;
    color: black;
    border: 1px solid rgba(128, 128, 128, 0.411);
    padding: 0px 7px;
    border-radius: 5px;
    cursor: pointer;
}

.checkout-sub-container .cart-icon-wrap .cart-edit-icon .fa-pencil {
    margin-top: 6px;
    margin-right: 0px;
}

/* .checkout-container .cart-payment-container {
    width: 40%;
} */

.checkout-container .cart-payment-container .payment-hd {
    font-size: 45px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 20px;
    color: black;
}

.checkout-container .cart-payment-container .cart-payment-form {
    border: 1px solid #80808069;
    border-radius: 20px;
    padding: 35px 15px;
    width: 100%;
}

.checkout-container .cart-payment-container .cart-input-wrap {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
}

.checkout-container .cart-payment-container .cart-input-label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkout-container .cart-payment-container .cart-input-label input {
    padding: 12px 10px;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.699);
    width: 100%;
}

.checkout-container .cart-payment-container .cart-input-label p {
    margin: 0px;
    font-size: 18px;
    margin-bottom: 6px;
    color: rgb(61, 60, 60);
}

.checkout-container .cart-payment-container .cart-input-label .cart-important-sign {
    color: red;
}

.checkout-container .cart-payment-container .code-expiry-input-wrap {
    display: flex;
    gap: 30px;
    /* width: 48%; */
}

.checkout-container .cart-payment-container .zipcode-name-input-wrap {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
}

.checkout-container .cart-payment-container .zipcode-name-input-wrap .zipcode-wrap {
    width: 40%;
}

.checkout-container .cart-payment-container .zipcode-name-input-wrap .name-wrap {
    width: 60%;
}

.checkout-container .cart-payment-container .coupon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    border: 1px solid black;
    padding: 10px;
    border-radius: 20px;
}

.checkout-container .cart-payment-container .coupon-container .coupon-hd {
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
}

.checkout-container .cart-payment-container .coupon-container .coupon-input-btn-wrap {
    display: flex;
    align-items: center;
    /* margin-top: 10px; */
    width: 60%;
    position: relative;
}

.checkout-container .cart-payment-container .coupon-container .coupon-input {
    /* width: 60%; */
    background-color: #f5f5f5;
    border: none;
    outline: none;
    /* padding: 25px; */
    border-radius: 20px;
    height: 60px;
}

.checkout-container .cart-payment-container .coupon-container .coupon-btn {
    background-color: black;
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 0px 16px;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 60px;
}

.checkout-container .cart-payment-container .total-price-container {
    border: 2px solid var(--main-purple);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 44px !important; */
    margin-top: 12px;

    padding: 15px 10px;
    gap: 10px;
    /* padding: 30px 10px; */
    /* border-radius: 20px; */
    border-radius: 15px;
    background-color: #f6ebff;
}
.checkout-container .cart-payment-container .total-price-container .total-price {
    font-size: 20px !important;
}

.checkout-container .cart-payment-container .total-price-container p {
    margin: 0px;
    color: var(--main-purple);
    font-size: 20px;
    font-weight: 600;
}

.checkout-container .cart-payment-container .place-order-container {
    width: 100%;
    padding: 0;
    gap: 10px;
    align-items: center;
    color: var(--main-purple);
    border-radius: 15px;
    font-size: 20px;
}

.checkout-container .cart-payment-container .place-order-btn {
    border: none;
    outline: none;
    background-color: var(--main-purple);
    width: 100%;
    color: white;
    border: 2px solid var(--main-purple);
    font-size: 20px;
    font-weight: 700;
    padding: 15px 10px;
    border-radius: 15px;
}
.coupon-code-applied {
    font-size: 14px;
    font-weight: 600;
    color: var(--main-purple);
    margin-bottom: 0px;
}
.modal .modal-dialog.checkout-modal {
    max-width: 600px !important;
}

@media (max-width: 1024px) {
    .checkout-container .checkout-sub-container {
        flex-direction: column;
        align-items: center;
    }
    .checkout-container .checkout-sub-container .order-summary-container,
    .checkout-container .cart-payment-container {
        width: 100%;
    }
    .checkout-container .checkout-sub-container {
        gap: 60px;
    }
    .checkout-container .cart-payment-container .total-price-container,
    .checkout-container .cart-payment-container .place-order-btn {
        padding: 20px 10px;
        font-size: 22px;
    }
    /* .checkout-container .cart-payment-container .total-price-container p {
        font-size: 22px;
    } */
    .checkout-sub-container .order-summary-container .order-summary-hd,
    .checkout-container .cart-payment-container .payment-hd {
        font-size: 35px;
    }
}

@media (max-width: 550px) {
    .checkout-sub-container .order-summary-container .order-summary-hd {
        font-size: 25px;
        line-height: 25px;
        text-align: center;
    }

    .checkout-container .cart-payment-container .cart-payment-form {
        border-radius: 10px;
        padding: 25px 15px;
    }

    .checkout-container .cart-payment-container .cart-input-wrap {
        gap: 10px;
    }

    .checkout-sub-container .cart-item-name {
        font-size: 14px;
    }

    .checkout-sub-container .cart-item-data {
        font-size: 10px;
    }

    .checkout-sub-container .cart-item-hd {
        font-size: 12px;
    }

    .checkout-sub-container .cart-item-price {
        font-size: 14px;
        /* margin-top: 10px; */
    }

    .checkout-sub-container .cart-icon-wrap {
        flex-direction: column;
        justify-content: center;
    }

    .checkout-container .checkout-sub-container .cart-item {
        padding: 10px;
        gap: 20px;
    }

    .checkout-container .cart-payment-container .payment-hd {
        font-size: 25px;
        font-weight: 700;
        text-align: center;
    }

    .checkout-container .cart-payment-container .cart-input-label p {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .checkout-container .cart-payment-container .coupon-container {
        padding: 2px 5px;
        border-radius: 10px;
        margin-top: 15px;
    }

    .checkout-container .cart-payment-container .coupon-container .coupon-hd {
        font-size: 14px;
    }

    .checkout-container .cart-payment-container .coupon-container .coupon-input-btn-wrap {
        display: flex;
        align-items: center;
        width: 60%;
        position: relative;
    }

    .checkout-container .cart-payment-container .coupon-container .coupon-input {
        height: 40px;
    }

    .checkout-container .cart-payment-container .coupon-container .coupon-btn {
        border-radius: 10px;
        padding: 0px 6px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .checkout-container .cart-payment-container .total-price-container {
        padding: 8px 5px;
        border-radius: 10px;
        margin-top: 5px;
    }

    /* .checkout-container .cart-payment-container .total-price-container p {
        font-size: 16px;
        font-weight: 700;
    } */

    .checkout-container .cart-payment-container .place-order-container {
        margin-top: 10px;
    }

    .checkout-container .cart-payment-container .place-order-btn {
        font-size: 16px;
        padding: 8px 5px;
        border-radius: 10px;
    }

    .order-summary-img {
        width: 88px;
    }

    .checkout-sub-container .cart-item-img {
        min-width: 0;
        height: auto;
    }
}

iframe {
    border: 0;
    width: 100%;
    height: 60px;
}
p.label {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 3px;
    color: #4b4b4b;
}
.modal .modal-dialog {
    max-width: 800px !important;
}
.modal .modal-dialog .modal-content {
    border-radius: 30px !important;
}
.modal-title {
    font-size: 45px !important;
    text-align: left !important;
    font-weight: 700 !important;
    font-family: var(--Nexa-font);
    color: rgba(0, 0, 0, 0.781) !important;
}
/* .modal-body {
    padding: var(--bs-modal-padding);
} */
.modal-dialog .modal-content {
    padding: 11px;
    border: 0 !important;
}
.checkout-container input {
    border: 2px solid #d8d7d7d5;
    border-radius: 15px !important;
    width: 100% !important;
    height: 60px !important;
    box-shadow: none !important;
    padding: 25px 0px 25px 20px !important;
}
.checkout-container input.form-control {
    border-right: none !important;
}
.cardExpiry {
    width: 94% !important;
}
.modal label {
    font-family: "Nexa", sans-serif !important;
    font-weight: 400 !important;
}
iframe {
    padding: 0 !important;
    overflow: hidden !important;
}
.expiry_container {
    width: 100% !important;
    /* margin-top: -14px !important; */
}
.password-form-group {
    margin-bottom: 14px !important;
}
.create_password_btn {
    border: none;
    background: none;
    text-decoration: underline;
    color: var(--main-purple);
}

@media screen and (max-width: 768px){
    .modal-title {
        font-size: 35px !important;
    }
}

@media screen and (max-width: 576px){
    .modal-title {
        font-size: 30px !important;
    }
    .expiry_container .w-50 {
        width: 100% !important;
    }
}

.coupon-toggle {
    display: block;
    opacity: 1;
    max-height: 30px;
    transition: all 0.4s ease-out;
    margin-bottom: 10px;
    cursor: pointer;
    color: var(--main-purple) !important;
    font-size: 16px;
    font-weight: 600;
}

.coupon-toggle.hide {
    opacity: 0;
    max-height: 0;
    margin: 0;
    visibility: hidden;
    position: absolute;
}

.coupon-input-container {
    position: relative;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;
    transform: translateY(20px);
}

.coupon-input-container.show {
    max-height: 100px;
    opacity: 1;
    transform: translateY(0);
}

.coupon-input-container .input-group {
    margin: 0;
    padding: 0;
}

.coupon-code-form-group {
    min-height: 4.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.coupon-code-form-group .form-control {
    width: 1% !important;
    border-radius: 15px 0 0 15px !important;
    margin: 0 !important;
}

.coupon-code-apply-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 15px 15px 0 !important;
    border-left: none !important;
}
