.dp__container {
    background-color: #fff;
    font-family: "Nexanew2";
    /* min-height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.dp__title {
    text-align: center;
    font-family: "Nexanew2";
    font-weight: 600;
    font-size: 30px;
    color: #000;
}

.dp__flex-wrapper {
    display: flex;
    width: 100%;
    padding: 30px;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.dp__left {
    width: 600px;
    height: fit-content;
    border-radius: 7px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp__left,
.dp__right {
    margin: 0 15px;
}

.dp__buttons {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
}

#dp__download-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #c4c4c491;
    border-radius: 7px;
    z-index: 2;
}

.dp__format-button {
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
    transition: 0.1s;
    position: relative;
    padding: 9px 16px;
    background-color: #ba26ff;
    display: flex;
}

.dp__format-button:hover {
    background-color: #dcdcdc;
}

.dp__format-button:hover .dp__format-text {
    color: #0f0f0f;
}

.dp__format-button:hover img {
    filter: invert(0);
}

.dp__format-button.disabled {
    cursor: progress;
    background-color: #f2f2f2;
}

/* svg black image to white */
.dp__format-button img {
    filter: invert(1);
    margin-right: 10px;
}

.dp__format-title {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
}

.dp__format-text {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin-top: 6px;
    font-family: "Nexanew2";
    padding-right: 5px;
}

#video-load-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(79 79 79 / 38%);
    border-radius: 10px;
}

.dp__btn-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp__btn-item-description {
    font-family: "Nexanew2";
    font-weight: 300;
}

.dp_icon_btn_img {
    width: 20px !important;
    height: 20px !important;
}

@media screen and (max-width: 500px) {
    .dp__format-button {
        padding: 9px 12px !important;
    }
    .dp__format-text {
        font-size: 14px !important;
        margin-top: 2px !important;
    }
    .dp__format-button img {
        margin-left: 3px !important;
        margin-right: 5px !important;
    }
    /* NOTE add this to global wordpress css */
    .dp__btn-item-description {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 375px) {
    .dp__title {
        font-size: 24px;
    }
    .dp__buttons {
        padding: 10px;
    }
    .dp__container {
        justify-content: space-around;
    }
    .dp__format-button {
        padding: 7px 19px !important;
        margin: 7px;
    }
    .dp__btn-item-description {
        font-size: 14px !important;
    }
}

#dp_download-overlay,
.dp_buttons {
    width: 100% !important;
}
.dp__buttons .row .mt-4 {
    padding: 0 !important;
    width: 100% !important;
}
.dp__container {
    justify-content: start;
}

.modal-header {
    justify-content: space-between;
}
.modal-header button {
    border: none;
    background: none;
}
.modal-header button span {
    font-size: 30px !important;
}
.dp__buttons {
    width: 100% !important;
}
.order-content {
    display: flex !important;
    box-shadow: 0 0 20px -10px gray;
    border-radius: 7px !important;
    padding: 0 8px !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.order-content h4 {
    margin-bottom: 0 !important;
}
.order-content .text-div h4:first-child() {
    font-weight: 700 !important;
}
.order-content .text-div h4:nth-child(2) {
    font-size: 19px !important;
    font-weight: 500 !important;
}
.order-content .download-btn {
    align-items: center !important;
    background-color: #a740f7 !important;
    border: 2px solid #a740f7 !important;
    border-radius: 10px !important;
    color: #fff !important;
    display: flex !important;
    gap: 10px !important;
    padding: 6px 30px !important;
}

.modal-download.modal-body {
    display: flex !important;
    justify-content: center !important;
}

.circle-loader{
    height: 23px;
    width: 23px;
    border: 3px solid rgb(230, 230, 230);
    border-bottom-color: #BF5ADE;
    margin-top: -8px;
    margin-right: 5px;
}
