.convenience-simcha-container {
    margin-top: 350px;
    font-family: var(--Nexa-font);
    width: 100%;
    display: flex;
    justify-content: center;
}

.convenience-simcha-container .convenience-simcha-sub-container {
    max-width: var(--container-max-width);
}

.convenience-simcha-container .convenience-hd {
    font-size: var(--hd-font-size);
    font-weight: var(--font-weight-600);
    line-height: 72px;
    text-align: center;
    margin-bottom: 57px;
}

.convenience-simcha-container .convenience-bold-text {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    color: black;
    margin: 0px;
    letter-spacing: -0.648px;
}

.convenience-simcha-container .convenience-text {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    padding: 5px 150px;
    font-weight: 500;
    letter-spacing: -0.648px;
}

.convenience-simcha-container .convenience-sub-hd {
    color: var(--main-purple);
    font-size: 32px;
    text-align: center;
    letter-spacing: 14px;
    font-weight: 600;
    margin-top: 40px;
}

.convenience-simcha-container .card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 70px;
    margin-top: 116px;
    margin-bottom: 20px;
}

.convenience-simcha-container .card-container .card-wrap {
    border: 2px solid #707070;
    border-radius: 38px;
    padding: 33px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    max-width: 210px;
}

.convenience-simcha-container .card-container .img-wrap {
    margin-bottom: 20px;
}

.convenience-simcha-container .card-container .card-text {
    font-size: 22px;
    text-align: center;
    font-weight: normal;
    margin-top: 14px;
    line-height: 30px;
}

.convenience-simcha-container .pick-shick-container {
    background-color: #fdf7ff;
    border-radius: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -120px 0px 90px 0;
}

.convenience-simcha-container .pick-shick-container .pick-shick-hd {
    font-size: var(--hd-font-size);
    margin-bottom: 29px;
    margin-top: 168px;
    font-weight: var(--font-weight-600);
    text-align: center;
    line-height: 72px;
}

.convenience-simcha-container .pick-shick-container .pick-shick-button {
    background-color: #fdf7ff;
    border: 2px solid var(--main-purple);
    padding: 16px 31px;
    font-size: 17px;
    border-radius: 15px;
    color: var(--main-purple);
    font-weight: 600;
    margin-bottom: 60px;
    height: 62px;
    align-items: center;
    display: flex;
}

.convenience-simcha-container .pick-shick-container .pick-shick-button:hover {
    animation: bounce 0.3s;
    background-color: var(--main-purple);
    color: white;
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
        background-color: var(--main-purple);
        color: white;
    }
    100% {
        transform: scale(1);
    }
}
@media (max-width: 1200px) {
    .convenience-simcha-container {
        margin-top: 320px;
        font-family: var(--Nexa-font);
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 1024px) {
    .convenience-simcha-container .convenience-hd {
        font-size: 34px;
        line-height: 50px;
    }

    .convenience-simcha-container .convenience-bold-text {
        font-size: 20px;
        letter-spacing: -0.8px;
    }

    .convenience-simcha-container .convenience-text {
        font-weight: 400;
        font-size: 20px;
        padding: 0px 30px;
        letter-spacing: -0.8px;
    }

    .convenience-simcha-container .convenience-sub-hd {
        font-size: 24px;
        text-align: center;
        letter-spacing: 14px;
        font-weight: 500;
        margin-top: 40px;
    }

    .convenience-simcha-container .card-container {
        gap: 30px;
        padding: 0px 50px;
        margin-bottom: 40px;
        justify-content: center;
    }

    .convenience-simcha-container .card-container .card-wrap {
        border: 2px solid #707070;
        border-radius: 38px;
        padding: 68px 40px 30px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fafafa;
        max-width: 140px;
    }

    .convenience-simcha-container .card-container .img-wrap {
        margin-bottom: 0px;
    }

    .convenience-simcha-container .card-container .card-text {
        font-size: 14px;
        line-height: 20px;
    }

    .convenience-simcha-container .pick-shick-container .pick-shick-hd {
        font-size: 34px;
        line-height: 50px;
        margin-bottom: 30px;
        margin-top: 100px;
    }

    .convenience-simcha-container .pick-shick-container .pick-shick-button {
        padding: 14px 32px;
        font-size: 14px;
    }

    .convenience-simcha-container {
        margin-top: 180px;
    }
}

@media (max-width: 992px) {
    .convenience-simcha-container {
        margin-top: 170px;
    }
}

@media (max-width: 767px) {
    .convenience-simcha-container .convenience-hd {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 20px;
    }

    .convenience-simcha-container .convenience-bold-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: normal;
    }

    .convenience-simcha-container .convenience-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: normal;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .convenience-simcha-container .convenience-sub-hd {
        font-size: 12px;
        letter-spacing: 8px;
        margin-top: 20px;
    }

    .convenience-simcha-container .card-container {
        margin-top: 70px;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .convenience-simcha-container .pick-shick-container {
        background-color: #fdf7ff;
        border-radius: 38px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -100px 0px 0px 0px;
        border-radius: unset;
    }

    .convenience-simcha-container .pick-shick-container .pick-shick-hd {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 30px;
        margin-top: 190px;
    }

    .convenience-simcha-container {
        margin-top: 140px;
    }

    .convenience-simcha-container .card-container .card-wrap {
        padding: 30px 40px 30px 40px;
    }

    .convenience-simcha-container .convenience-simcha-sub-container {
        width: 100%;
    }
    .convenience-simcha-container .pick-shick-container .pick-shick-button {
        height: 44px;
    }
}

@media (max-width: 550px) {
    .convenience-simcha-container .card-container .card-wrap {
        max-width: 110px;
        border-radius: 15px;
        border: 1px solid black;
        padding: 22px 40px;
    }

    .convenience-simcha-container .card-container {
        gap: 10px;
        padding: 0px 15px;
        flex-wrap: wrap;
        margin-top: 50px;
    }

    .convenience-simcha-container .card-container .card-text {
        font-size: 10px;
        line-height: 12px;
    }
    .convenience-simcha-container .pick-shick-container .pick-shick-hd {
        font-size: 26px;
        margin-bottom: 30px;
        margin-top: 110px;
    }

    .convenience-simcha-container {
        margin-top: 100px;
    }

    .convenience-simcha-container .convenience-text {
        margin-top: 5px;
        padding: 0;
    }

    /* .convenience-simcha-container .convenience-simcha-sub-container {
        max-width: 85%;
    } */

    .convenience-simcha-container .pick-shick-container .pick-shick-button {
        /* background-color: #fdf7ff;
        border: 2px solid var(--main-purple); */
        padding: 10px 17px;
        font-size: 12px;
        border-radius: 15px;
        /* color: var(--main-purple);
        font-weight: 600;
        margin-bottom: 50px; */
    }
    .convenience-simcha-container .convenience-hd {
        font-size: 26px;
    }
    .convenience-simcha-container {
        margin-top: 100px;
    }
}
@media (min-width: 2350px) {
    .convenience-simcha-container {
        margin-top: 500px;
    }
}
