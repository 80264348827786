.categories-container-home {
    --column-size: 160px;
}

.categories-container-home {
    padding: 30px 0 110px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.categories-grid-home {
    width: 100%;
    display: grid;
    gap: 35px;
    grid-template-columns: repeat(5, var(--column-size));
    justify-content: center;
}

.categories-flex-home {
    max-width: 950px;
    margin: 0 auto;
}

.categories-flex-home > div {
    gap: 35px;
}

.categories-card {
    width: 160px;
    height: 160px;
}

.category-list-title {
    text-align: center;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 35px;
    margin-bottom: 30px;
}

.chi {
    height: var(--column-size);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.1s;
}

.chi:hover {
    box-shadow: none;
}

.chi-link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
}

.chi-image {
    display: flex;
    justify-content: center;
    width: 80px;
}

.chi-image img {
    width: auto;
    height: auto;
    max-height: 60px;
    /* transition: .1s; */
}

.chi-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chi-text {
    color: #333;
    font-weight: 500;
    margin-top: 15px;
    font-size: 15px;
    font-family: helvetica;
    transition: 0.1s;
    font-family: Nexa, Sans-serif;
}

/* media queries */
@media screen and (max-width: 1200px) {
    .categories-grid-home {
        grid-template-columns: repeat(4, var(--column-size));
        gap: 20px;
    }
}

/* @media screen and (max-width: 900px){
    .categories-grid-home{
        grid-template-columns: repeat(3, calc(var(--column-size) - 10px) );
    }
} */

@media screen and (max-width: 730px) {
    .categories-grid-home {
        grid-template-columns: repeat(3, calc(var(--column-size) - 10px));
        gap: 10px;
    }
    .chi-image {
        width: 60px;
        /* height: 40px; */
    }
    .chi-image img {
        width: 40px;
        height: 40px;
        /* transition: .1s; */
    }
    .categories-card {
        width: 100px;
        height: 100px;
    }
    .categories-flex-home > div {
        gap: 25px;
    }
    .categories-container-home {
        padding-bottom: 50px;
    }
    .chi-text {
        font-size: 13px;
        margin-top: 5px;
    }
}
@media (max-width: 1024px) {
    .categories-flex-home > div {
        gap: 17px;
    }
    .categories-container-home { 
        padding-bottom: 90px;
    }
}

@media screen and (max-width: 576px) {
    .categories-grid-home {
        grid-template-columns: repeat(2, calc(var(--column-size) - 10px));
    }
    .categories-container-home {
        padding-bottom: 70px;
    }
}

@media screen and (max-width: 420px) {
    .categories-grid-home {
        grid-template-columns: repeat(3, calc(var(--column-size) - 50px));
    }
    .categories-flex-home > div {
        gap: 20px;
    }
    .categories-card {
        width: 98px;
        height: 98px;
    }
    .chi-text {
        font-size: 10px;
    }
}
