.cartbtn-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* same as inputs max-width in inputList.css */
    max-width: 380px;
}

.cartbtn-container button{
    border-radius: 10px;
    height: 35px;
    font-size: 16px;
    border: none;
    width: 160px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .15s;
    position: relative;
}

#cartbtn-cart{
    border: 2px solid #000;
    background: #f8f8f8;
    position: relative;
}

#cartbtn-cart:hover{
    background: #ececec;
}
#cartbtn-cart:disabled{
    background: #ececec;
    cursor: not-allowed;
}

#cart-loading-btn,
#buy-loading-btn{
    position: absolute;
    left: 12px;
    /* top: 7px; */
}
