body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #969696;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-weight: 700;
  line-height: 44px;
  font-size: 36px;
}

h2 {
  font-weight: 700;
  line-height: 38px;
  font-size: 30px;
}

h3 {
  font-weight: 700;
  line-height: 34px;
  font-size: 26px;
}

h4 {
  font-weight: 500;
  line-height: 30px;
  font-size: 22px;
}

h5 {
  font-weight: 500;
  line-height: 28px;
  font-size: 20px;
}

h6 {
  font-weight: 500;
  line-height: 22px;
  font-size: 18px;
}

/***************************
    REVOLUTION SLIDER
***************************/
#rev_slider_15_1 .metis.tparrows {
  background: white;
  padding: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
}

#rev_slider_15_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.75);
}

#rev_slider_15_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}

#rev_slider_15_1 .metis.tparrows:before {
  color: black;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

/***************************
    HEADER CSS
***************************/

/***************************
    FOOTER CSS
***************************/

/***************************
    LAYOUT CSS
***************************/
.single-img-wrapper {
  position: relative;
}

.single-img-wrapper .text-wrapper {
  text-align: center;
  padding: 20px 15px;
}

.single-img-wrapper .text-wrapper h5 {
  margin-bottom: 0;
}

.single-img-wrapper .text-wrapper p {
  margin-bottom: 0;
}

.single-img-wrapper .single-img-content {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 220px;
}

.single-img-wrapper .single-img-content.content-vbottom {
  bottom: 60px;
  top: inherit;
}

.single-img-wrapper.single-img-wrapper-1 {
  padding-right: 10%;
}

.single-img-wrapper.single-img-wrapper-1 .text-wrapper {
  margin-top: 30px;
}

.single-img-wrapper.single-img-wrapper-1 .single-img-content {
  left: 220px;
  top: 0;
}

.single-img-wrapper.single-img-wrapper-2 {
  padding-left: 10%;
}

.single-img-wrapper.single-img-wrapper-2 .text-wrapper {
  margin-bottom: 30px;
}

.single-img-wrapper.single-img-wrapper-2 .single-img-content {
  left: 0px;
}

.single-img-wrapper.single-img-wrapper-3 {
  padding-left: 10%;
}

.single-img-wrapper.single-img-wrapper-3 .text-wrapper {
  margin-top: 95px;
}

.single-img-wrapper.single-img-wrapper-3 .single-img-content {
  top: inherit;
  left: 0;
  bottom: 50px;
  width: 100%;
  text-align: center;
  padding-left: 10%;
  margin-bottom: 70px;
}

.single-img-wrapper.single-img-wrapper-3 .single-img-content .border-line {
  margin-right: 40%;
  margin-left: 40%;
}

.border-line {
  height: 2px;
  margin-top: 60px;
  margin-right: 80%;
  margin-bottom: 10px;
  background-color: #c1eade;
}

.bg-sky {
  background: #e3f6fc;
}

.bg-orange {
  background: #fde1d6;
}

.bg-green {
  background: #cdf6ec;
}

a.inline_hover {
  position: relative;
  background: transparent;
  color: #323232;
  font-size: 14px;
  padding: 3px 0;
  line-height: 22px;
}

a.inline_hover:after {
  content: "";
  width: 100%;
  border-bottom: 2px solid;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

a.inline_hover:hover {
  background: transparent;
}

a.inline_hover:hover:after {
  width: 30%;
}

.products .product .onsale.featured {
  top: 41px !important;
  background: #f4524d;
  text-transform: uppercase;
}

.products .product .onsale.featured:before,
.products .product .onsale.featured:after {
  border-left-color: #f4524d;
  color: #f4524d;
}

.countdown-main {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 140px;
  padding-top: 140px;
}

.countdown-main .section-title-wrapper h2 {
  font-size: 78px;
  line-height: 78px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.countdown-main .section-title-wrapper p {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #323232;
  margin-bottom: 0;
}

.countdown {
  padding: 0;
  margin: 15px 0 0 !important;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.countdown li {
  margin: 0;
  margin-right: 5px;
  border: none;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  min-width: 50px;
  background: #323232;
  padding: 8px 10px;
  border-radius: 3px;
}

.countdown li span {
  display: block;
  font-size: 22px;
  color: #fff;
  line-height: 32px;
  font-weight: 600;
}

.countdown li p {
  background: #323232;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.newsletter-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.newsletter-section .newsletter-wrapper .newsletter-title {
  font-weight: 700;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .input-area .newsletter-email {
  background: #fff !important;
  color: #323232 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .input-area .newsletter-email::placeholder {
  color: #969696 !important;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area .btn.submit,
.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area:before {
  color: #323232;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area .btn.submit:hover,
.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area .btn.submit:focus {
  color: #04d39f;
}

.ciyashop_clients {
  padding-top: 50px;
  padding-bottom: 50px;
}

.owl-carousel .owl-item img {
  width: auto;
}

.instragram-wrapper {
  margin-bottom: 80px;
}
